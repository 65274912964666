import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import { useNavigate, useSearchParams } from 'react-router-dom';

const EvaluationList = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [currentPage] = useState(searchParams.get("page") || 1);
  const [itemsPerPage] = useState(searchParams.get("pageSize") || 25)

  const [loading, setLoading] = useState(true);
  const [evaluates, setEvaluates] = useState([]);

  useEffect(() => {
    if (!searchParams.has('pageSize')) {
      setSearchParams({ ...searchParams, itemsPerPage });
      navigate(`?page=${currentPage}&pageSize=${itemsPerPage}`);
    }

    const getAllEvaluates = async () => {
      setLoading(true);
      const data = await api.adminGetAllEvaluates({ page: currentPage, pageSize: itemsPerPage });
      setEvaluates(data);
      setLoading(false);
    }

    getAllEvaluates();
  }, [currentPage, itemsPerPage, navigate, searchParams, setSearchParams]);

  const handleNavigateToPage = (pageNumber) => {
    setSearchParams({ ...searchParams, page: pageNumber });
    navigate(`?page=${pageNumber}&pageSize=${itemsPerPage}`);
  }

  const handlePageSize = (pageSize) => {
    setSearchParams({ ...searchParams, pageSize: pageSize });
    navigate(`?page=${currentPage}&pageSize=${pageSize}`)
  }

  return (
    <div className='card'>
      <div className="card-body table-responsive">
        <div className="d-flex justify-content-between mb-3">
          <h1 className='h5'>Mahasiswa</h1>
          <div className="col-2">
            <select value={itemsPerPage} onChange={(e) => handlePageSize(e.target.value)} className="form-select" aria-label="Default select example">
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Mentors</th>
              <th scope="col">Mentess</th>
              <th scope="col">Jurusan</th>
              <th scope="col">Univesitas</th>
              <th scope="col">Divisi</th>
              <th scope="col">Nilai</th>
              <th scope="col">PAT</th>
            </tr>
          </thead>
          <tbody>
            {loading ?
              <>
                {Array.apply(null, Array(Number(itemsPerPage))).map(() => (
                  <tr className='placeholder-glow'>
                    <th><span className='placeholder col-12'></span></th>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                  </tr>
                ))}
              </>
              :
              <>
                {evaluates?.data?.map((evaluate, key) => (
                  <tr key={key} onClick={() => navigate(`/admin/evaluation/${evaluate.id}`)} style={{ cursor: 'pointer' }}>
                    <th scope="row">{(currentPage - 1) * itemsPerPage + (key + 1)}</th>
                    <td>{evaluate.mentors}</td>
                    <td>{evaluate.mentess}</td>
                    <td>{evaluate.jurusan}</td>
                    <td>{evaluate.universitas}</td>
                    <td>{evaluate.divisi}</td>
                    <td>{evaluate.nilai}</td>
                    <td width={10 + "%"} colSpan={2}>
                      <div class="progress" role="progressbar" aria-label="Example with label" aria-valuenow={evaluate.progressPAT.progress} aria-valuemin="0" aria-valuemax="100">
                        <div class="progress-bar" style={{ width: evaluate.progressPAT.progress + '%' }}>{`${evaluate.progressPAT.hasEvaluated}/${evaluate.progressPAT.totalByDivisi}`}</div>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            }
          </tbody>
        </table>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item">
              <button onClick={() => handleNavigateToPage(evaluates.previous)} class={`page-link ${!evaluates.previous && 'disabled'}`}>Previous</button>
            </li>
            <li class="page-item"><p class="page-link">{evaluates.current + 1 || parseInt(currentPage)}</p></li>
            <li class="page-item">
              <button onClick={() => handleNavigateToPage(evaluates.next + 1)} class={`page-link ${!evaluates.next && 'disabled'}`}>Next</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default EvaluationList
import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import { Link } from 'react-router-dom';

const PublicationList = () => {
  const [status, setStatus] = useState([]);
  const [pubStatus, setPubStatus] = useState(0);
  const [pub, setPub] = useState([]);

  useEffect(() => {
    const getStatusPub = async () => {
      const data = await api.getStatusPublicationsAdmin();
      setStatus(data);
    }

    getStatusPub();
  }, []);

  useEffect(() => {
    const getPublications = async () => {
      const data = await api.getPublicationsAdmin({ publicationStatus: pubStatus, pageSize: 10, page: 1 });
      setPub(data);
    }

    getPublications();
  }, [pubStatus]);

  return (
    <div className='row'>

      <div className='col-12'>
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between mb-3">
              <h1 className='h5'>Publikasi</h1>
              {/* <Link to="/admin/publication" className='btn btn-primary text-light'>Semua</Link> */}
              <div className="col-3">
                <select onChange={(e) => setPubStatus(e.target.value)} class="form-select" aria-label="Default select example">
                  {status?.map((e, key) => (
                    <option key={key} value={e.id}>{e.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row row-cols-1 g-3">
              {!pub?.publications?.length > 0 && <p className='text-center'>belum ada publikasi!</p>}
              {pub?.publications?.map((e, key) => (
                <div class="col" key={key}>
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">{e.title}</h5>
                      <p class="card-text">{e.abstract}</p>
                      <Link to={`/admin/publication/${e.id}`} class="btn btn-primary">Detail</Link>
                    </div>
                  </div>
                </div>
              ))}

            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default PublicationList
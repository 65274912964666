import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';
import api from '../../../utils/api';

const PublicationDetail = () => {
  const location = useLocation();

  const path = location.pathname.split('/');
  const id = path[3];

  const [pub, setPub] = useState([]);
  const [reviews, setReviews] = useState({});
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const getPublicationsById = async () => {
      const publications = await api.adminGetPublicationsById({ id });
      setPub(publications[0]);
      setRefresh(false);
    }

    getPublicationsById();
  }, [refresh, id]);

  const onHandlePostReviews = async () => {
    await api.adminPostReviewsForPublications({ id, payload: reviews });
    setReviews({ message: '' });
    setRefresh(true);
  }

  const onHandlePutReviews = async ({ reviewId, message }) => {
    await api.adminPutReviewsForPublications({ publicationId: id, reviewId, payload: { message } });
    setRefresh(true);
  }

  const onHandleDeleteReviews = async ({ reviewId }) => {
    await api.adminDeleteReviewsForPublications({ publicationId: id, reviewId });
    setRefresh(true);
  }

  const onHandlePutStatus = async ({ publicationHistoryId }) => {
    await api.adminPutStatusHistoryForPublications({ publicationId: id, publicationHistoryId });
    setRefresh(true);
  }

  return (
    <div className='row'>
      <div className="col-12 col-md-8 mb-sm-3">
        <div className="row row-cols-1 g-3">

          <div className="col">
            <div className="card">
              <div className="card-body">
                <h1 className='h2'>{pub.title}</h1>
                <h2 className='h5'><Link className='text-decoration-none' to={`/admin/users/${pub.authorId}`}>{pub.author}</Link></h2>
                <h2 className='h6'>{pub.provinsi}</h2>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card">
              <div className="card-body">
                <h1 className='h5'>Topic Area:</h1>
                {pub?.topic?.split(",").map((e) => (
                  <li>{e}</li>
                ))}
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card">
              <div className="card-body">
                <a href={pub.url_file} className='btn btn-primary'>PDF</a>
                <h2 className='h5'>Submitted</h2>
                <p className='h6'>Submitted</p>
                <h2 className='h5'>Published</h2>
                <p className='h6'>{pub.published}</p>
                <h2 className='h5'>Abstract</h2>
                <p>{pub.abstract}</p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card">
              <div className="card-body">
                <h1 className='h5'>Catatan:</h1>
                <form class="w-100 me-3" role="textbox">
                  <div class="input-group mb-3">
                    <input value={reviews.message} onChange={(e) => setReviews({ ...reviews, message: e.target.value })} type="text" class="form-control" placeholder={`Pesan untuk ${pub.author}`} aria-label="Recipient's username" aria-describedby="button-send-review" />
                    <button disabled={!reviews.message} onClick={() => onHandlePostReviews()} class="btn btn-outline-secondary" type="button" id="button-send-review">Kirim</button>
                  </div>
                </form>
                <div className="row g-3">

                  {!pub?.reviews?.length > 0 && <p>Belum di review!</p>}

                  {pub?.reviews?.map((review, key) => (
                    <div key={key} className="col-12">
                      <div className="border p-3 rounded">
                        <div class="input-group">
                          <textarea onBlur={(e) => onHandlePutReviews({ reviewId: review.id, message: e.target.value })} onChange={(e) => { review.message = e.target.value; setPub({ ...pub }) }} value={review.message} rows={3} type="text" className="form-control" id="address" />
                          <span class="input-group-text">
                            <button onClick={() => onHandleDeleteReviews({ reviewId: review.id })} type="button" class="btn-close" aria-label="Close"></button>
                          </span>
                        </div>
                        <div class="form-text">{review.hasUpdated === 1 && 'Edited'} {review.updated_at}</div>
                      </div>
                    </div>
                  ))}

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="col-md-4">

        <div className="row row-cols-1 g-3">

          <div className="col">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h1 className='h5'>Status:</h1>
                  <p>
                    <span class={`badge rounded-pill text-bg-${pub.publication_status === 'Published' ? 'success' : 'warning'}`}>{pub.publication_status}</span>
                  </p>
                </div>

                <div class="list-group">

                  {pub?.statusHistory?.map((e, key) => (
                    <label key={key} class={`list-group-item d-flex gap-3 ${e.isChecked && 'text-muted text-decoration-line-through'}`}>
                      <input checked={e.isChecked} onClick={() => onHandlePutStatus({ publicationHistoryId: e.id })} class="form-check-input flex-shrink-0" type="checkbox" style={{ fontSize: '1.375em' }} />
                      <span class="pt-1 form-checked-content">
                        <strong>{e.publication_status}</strong>
                        <small class="d-block text-body-secondary">
                          <i class="bi bi-calendar-event"></i>
                          {e.updated_at}
                        </small>
                      </span>
                    </label>
                  ))}

                </div>

                {/* <hr class="my-3"></hr> */}

                {/* <button class="w-100 btn btn-primary" type="submit">Continue to Editing</button> */}
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  )
}

export default PublicationDetail;

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../../utils/api';

const EvaluationDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const path = location.pathname.split('/');
  const id = path[3];

  const [data, setData] = useState({});

  useEffect(() => {
    const getEvaluationsById = async () => {
      const data = await api.adminGetEvaluatesById({ id });
      setData(data);
    }

    getEvaluationsById();
  }, [id]);

  return (
    <div className="row g-3">
      <div className='shadow-sm'>
        <div className='card-body m-3'>
          <div className="d-flex justify-content-between mb-3">
            <h1 className="h5">Penilaian</h1>
            <div className="col-2">
              <div className="d-grid gap-2">
                {/* <button onClick={() => onHandleDeleteMentors()} className='btn btn-danger'>Hapus</button> */}
              </div>
            </div>
          </div>

          <div className="row g-3">
            <div className="col-12">
              <label htmlFor="Mentors" className="form-label">Mentors</label>
              <div className="input-group has-validation">
                <input disabled value={data?.mentors} type="text" className="form-control" id="Mentors" placeholder="Mentors" required />
              </div>
            </div>

            <div className="col-12">
              <label htmlFor="Mentess" className="form-label">Mentess</label>
              <div className="input-group has-validation">
                <input disabled value={data?.mentess} type="text" className="form-control" id="Mentess" placeholder="Mentess" required />
              </div>
            </div>

            <div className="col-12">
              <label htmlFor="Jurusan" className="form-label">Jurusan</label>
              <div className="input-group has-validation">
                <input disabled value={data?.jurusan} type="text" className="form-control" id="Jurusan" placeholder="Jurusan" required />
              </div>
            </div>

            <div className="col-12">
              <label htmlFor="Universitas" className="form-label">Universitas</label>
              <div className="input-group has-validation">
                <input disabled value={data?.universitas} type="text" className="form-control" id="Universitas" placeholder="Universitas" required />
              </div>
            </div>

            <div className="col-12">
              <label htmlFor="Divisi" className="form-label">Divisi</label>
              <div className="input-group has-validation">
                <input disabled value={data?.divisi} type="text" className="form-control" id="Divisi" placeholder="Divisi" required />
              </div>
            </div>

            <div className="col-12">
              <label htmlFor="Catatan" className="form-label">Catatan</label>
              <div className="input-group has-validation">
                <input disabled value={data?.catatan} type="text" className="form-control" id="Catatan" placeholder="Catatan" required />
              </div>
            </div>

            <div className="col-12">
              <label htmlFor="Nilai Rata-Rata" className="form-label">Nilai Rata-Rata</label>
              <div className="input-group has-validation">
                <input disabled value={data?.average_nilai} type="text" className="form-control" id="Nilai Rata-Rata" placeholder="Nilai Rata-Rata" required />
              </div>
            </div>

            <div className="col-12">
              <label htmlFor="Nilai Akhir" className="form-label">Nilai Akhir</label>
              <div className="input-group has-validation">
                <input disabled value={data?.total_nilai} type="text" className="form-control" id="Nilai Akhir" placeholder="Nilai Akhir" required />
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <h1 className="h5">Penilaian Antar Teman</h1>
            </div>

            <div className="col-12">
              <div class="progress" role="progressbar" aria-label="Example with label" aria-valuenow={data?.infoEvaluated?.progress} aria-valuemin="0" aria-valuemax="100">
                <div class="progress-bar" style={{ width: data?.infoEvaluated?.progress + '%' }}>{data?.infoEvaluated?.progress}%</div>
              </div>
            </div>

            <div className="col-6">
              <label htmlFor="Nama" className="form-label">Sudah dinilai</label>
              <div className="input-group has-validation">
                <input disabled value={data?.infoEvaluated?.hasEvaluated} type="text" className="form-control" id="Nama" placeholder="Nama" />
              </div>
            </div>

            <div className="col-6">
              <label htmlFor="Nama" className="form-label">Belum dinilai</label>
              <div className="input-group has-validation">
                <input disabled value={data?.infoEvaluated?.notEvaluated} type="text" className="form-control" id="Nama" placeholder="Nama" />
              </div>
            </div>

            <div className="col-12 table-responsive card p-3">
              <h1 className='h5'>Bakrie Center Foundation</h1>
              {!data?.by_bcf?.length < 1 &&
                <table className='table table-hover'>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">KPI</th>
                      <th scope="col">Nilai</th>
                      <th scope="col">Catatan</th>
                      <th scope="col">Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.by_bcf?.map((bcf, key) => (
                      <tr key={key} style={{ cursor: 'default' }}>
                        <th scope="row">{key + 1}</th>
                        <td>{bcf.klasifikasi}</td>
                        <td>{bcf.nilai}</td>
                        <td>{bcf.catatan}</td>
                        <td>{bcf.klasifikasi === 'Kerjasama & Keaktifan' && <button onClick={() => navigate(`/mentors/evaluation/${id}/pat`)} className='btn btn-primary badge rounded-pill'>Lihat</button>}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              }

            </div>

            <div className="col-12 table-responsive card p-3">
              <div className="d-flex justify-content-between mb-3">
                <h1 className='h5'>Mentors</h1>
                {data?.hardskills_by_mentors?.length < 1 &&
                  <div className="col-2">
                    <div className="d-grid gap-2">
                      <button onClick={() => navigate(`/mentors/evaluation/${id}/evaluate/${data.id_divisi_lembaga}`)} className='btn btn-primary'>Nilai</button>
                    </div>
                  </div>
                }
              </div>
              {!data?.hardskills_by_mentors?.length < 1 && !data?.softskills_by_mentors?.length < 1 ?
                <>
                  <table className='table table-hover'>
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">KPI Hardskill</th>
                        <th scope="col">Nilai</th>
                        <th scope="col">Catatan</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.hardskills_by_mentors?.map((hard, key) => (
                        <tr key={key} style={{ cursor: 'default' }}>
                          <th scope="row">{key + 1}</th>
                          <td>{hard.klasifikasi}</td>
                          <td>{hard.nilai}</td>
                          <td>{hard.catatan}</td>
                        </tr>
                      ))}
                    </tbody>
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">KPI Softskill</th>
                        <th scope="col">Nilai</th>
                        <th scope="col">Catatan</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.softskills_by_mentors?.map((soft, key) => (
                        <tr key={key} style={{ cursor: 'default' }}>
                          <th scope="row">{key + 1}</th>
                          <td>{soft.klasifikasi}</td>
                          <td>{soft.nilai}</td>
                          <td>{soft.catatan}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </> :
                <p className='text-center'>Anda belum menilai!</p>
              }
            </div>

            <div className="col-12 table-responsive card p-3">
              <h1 className='h5'>Nilai MSIB</h1>

              {!data?.evaluate_for_msib?.length < 1 ?
                <table className='table table-hover'>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Nilai</th>
                      <th scope="col">Catatan</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.evaluate_for_msib?.map((msib, key) => (
                      <tr key={key} style={{ cursor: 'default' }}>
                        <th scope="row">{key + 1}</th>
                        <td>{msib.nilai}</td>
                        <td>Dinilai otomatis oleh sistem untuk MSIB!</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                :
                <p className='text-center'>Dinilai otomatis oleh sistem!</p>
              }
            </div>

          </div>

        </div>
      </div>
    </div >
  )
}

export default EvaluationDetail;

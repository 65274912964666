import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import { evaluationsKpiBCF } from '../../../utils/data';
import { useLocation, useNavigate } from 'react-router-dom';

const EvaluationAdd = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const path = location.pathname.split('/');
  const id = path[3];

  const [isLoading, setIsloading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [evaluates, setEvaluates] = useState(evaluationsKpiBCF);
  const [hasPAT, setHasPAT] = useState(true);
  const [mentess, setMentess] = useState({});

  useEffect(() => {
    const getClassificationsById = async () => {
      const { classifications, hasPAT } = await api.adminGetClassficationsBCFByUsersId({ id });
      // const evaluates = data.map((value) => ({ id: value.id, klasifikasi: value.klasifikasi, nilai: "", catatan: "" }))
      classifications.forEach((e) => {
        if (!e.nilai < 1) {
          setDisabled(false)
        }
      });
      setEvaluates(classifications);
      setHasPAT(hasPAT);
    }

    const getEvaluationsById = async () => {
      const data = await api.adminGetEvaluatesById({ id });
      setMentess(data);
    }

    getClassificationsById();
    getEvaluationsById();
  }, []);

  const onHandleChangeNilai = ({ nilai, key }) => {
    if (nilai > 100) { alert('Nilai tidak boleh lebih dari 100!'); return }
    const newState = [...evaluates];
    newState[key].nilai = nilai;
    setEvaluates(newState);
    if (newState[key].nilai < 1 || newState[4].nilai < 1) {
      setDisabled(true);
    } else {
      setDisabled(false)
    }
  }

  const onHandleChangeCatatan = ({ catatan, key }) => {
    const newState = [...evaluates];
    newState[key].catatan = catatan;
    setEvaluates(newState);
  }

  const onHandlePostEvaluate = async () => {
    setIsloading(true);
    const payload = { evaluates: evaluates };
    const message = await api.adminUpdateEvaluate({ id, payload });
    setTimeout(() => {
      alert(message);
      navigate(`/admin/evaluation/${id}`);
      setIsloading(false);
    }, 1000);
  }

  return (
    <div className='row g-3'>
      <div className="col-12 col-md-8">
        <div className="shadow-sm">
          <div className="p-3">
            <h1 className="h5">Nilai</h1>
            <div className="row g-3">

              {evaluates?.map((value, key) => (
                <div className="col-12">
                  <div className="row g-3">
                    <div className="col-6">
                      <label htmlFor="Klasifikasi" className="form-label">KPI {key + 1} {' '}
                        {hasPAT ? <>{key === 2 && <span class="badge rounded-pill text-bg-info">Dinilai otomatis oleh Sistem PAT!</span>}</> : <>{key === 2 && <span class="badge rounded-pill text-bg-warning">Dinilai manual oleh Admin BCF!</span>}</>}
                      </label>
                      <input disabled value={value.klasifikasi} type="text" className="form-control" id="Klasifikasi" />
                    </div>

                    <div className="col-6">
                      <label htmlFor="Nilai" className="form-label">Nilai</label>
                      <input disabled={hasPAT && evaluates[key].id === 3} value={evaluates[key].nilai} onChange={(e) => onHandleChangeNilai({ nilai: e.target.value, key })} type="number" placeholder='0' className="form-control" id="Nilai" min={1} max={100} required />
                    </div>

                    <div className="col-12">
                      <label htmlFor="Catatan" className="form-label">Catatan</label>
                      <textarea disabled={hasPAT && evaluates[key].id === 3} rows={2} value={evaluates[key].catatan} onChange={(e) => onHandleChangeCatatan({ catatan: e.target.value, key })} type="text" className="form-control" id="Catatan" required />
                    </div>
                  </div>
                </div>
              ))}

              <div className="d-grid gap-2">
                <button disabled={isLoading || disabled} className='btn btn-primary' type='button' onClick={() => onHandlePostEvaluate()}>
                  {isLoading ?
                    <>
                      <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                      <span role="status"> Loading...</span>
                    </>
                    :
                    <>
                      <span>Simpan</span>
                    </>
                  }
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="col-12 col-md-4 order-md-last order-first">
        <div className="row g-3 sticky-md-top" style={{ zIndex: 99 }}>
          <div className="shadow-sm">
            <div className='card-body m-3'>
              <h1 className="h5">Profil</h1>

              <div className="row g-3">
                <div className="col-12">
                  <label htmlFor="mentess" className="form-label">Mentess</label>
                  <input disabled value={mentess?.mentess} type="text" className="form-control" id="mentess" placeholder="Mentess" required />
                </div>
                <div className="col-12">
                  <label htmlFor="divisi" className="form-label">Divisi</label>
                  <input disabled value={mentess?.divisi} type="text" className="form-control" id="divisi" placeholder="Divisi" required />
                </div>
                <div className="col-12">
                  <label htmlFor="jurusan" className="form-label">Divisi</label>
                  <input disabled value={mentess?.jurusan} type="text" className="form-control" id="jurusan" placeholder="Jurusan" required />
                </div>
                <div className="col-12">
                  <label htmlFor="jurusan" className="form-label">Universitas</label>
                  <input disabled value={mentess?.universitas} type="text" className="form-control" id="universitas" placeholder="Universitas" required />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EvaluationAdd;

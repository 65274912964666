import React, { useState } from 'react';
import api from '../../utils/api'
import { useNavigate } from 'react-router-dom';
import * as bootstrap from 'bootstrap';

const Index = () => {
  const navigate = useNavigate();
  const [nim, setNim] = useState('A031211011');
  const [check, setCheck] = useState();

  const onHandlerCheckNim = async ({ nim }) => {
    const { status, data, message } = await api.getCheckUsersByNim({ nim });
    if (status === 'success') {
      setCheck(data);
      new bootstrap.Modal('#confirmationModal').show()
    } else {
      alert(message)
    }
  }

  const onHandlerChangeProfile = async ({ nim }) => {
    // modal.hide();
    return navigate('/update/diri', { state: { nim } });
  }

  const onHandlerDetailProfile = async ({ nim }) => {
    return navigate('/update/profile', { state: { nim } });
  }

  return (
    <>
      <div className='align-items-center'>
        <div className='w-100 m-auto' style={{ maxWidth: 400, padding: '1rem' }}>
          {/* <h2 className='text-center'>Server Maintenance</h2> */}
          <h1 className="h4 mb-3 fw-normal text-center">Masukan NIM/NPM</h1>

          <div className="form-floating mb-3">
            <input value={nim} onChange={(e) => setNim(e.target.value)} type="text" className="form-control" id="floatingInput" placeholder="NIM/NPM" />
            <label htmlFor="floatingInput">NIM/NPM</label>
          </div>
          <div className="d-grid gap-2 d-sm-flex mb-2">
            <button disabled={!nim} onClick={() => onHandlerCheckNim({ nim })} className="btn btn-primary w-100 py-2" type="button">Cek Data</button>
            <button disabled={!nim} onClick={() => onHandlerChangeProfile({ nim })} className="btn btn-outline-primary w-100 py-2" type="button">Lengkapi Data</button>
          </div>

          <div class="alert alert-primary d-flex align-items-center" role="alert">
            <div>
              Setelah melakukan update data, lihat detail untuk memeriksa kembali!
            </div>
          </div>

          <button disabled={!nim} onClick={() => onHandlerDetailProfile({ nim })} className="btn btn-outline-primary w-100 py-2" type="button">Lihat Detail</button>

          <h5 className='mt-3'>Alur Jika Ada Kesalahan / Kelupaan / Pembaruan Data</h5>
          <ul>
            <li>Masukan NPM/NIM lalu Lihat Detail</li>
            <li>Masukan NPM/NIM lalu Lengkapi Data (fokus ke yang salah/lupa data)</li>
            <li>Biarkan jika ada yang kosong (seperti checkbox domisili kota)</li>
            <li>Lalu klik Simpan</li>
            <li>Masukan NPM/NIM lalu Lihat Detail</li>
            <li>Lalu Pastikan Data Sesuai</li>
          </ul>
        </div>
      </div>

      <div className="modal fade" id="confirmationModal" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <p>Nama: {check?.nama}</p>
              <p>Asal Universitas: {check?.asal_universitas}</p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              {/* <button onClick={() => onHandlerChangeProfile({ nim })} type="button" className="btn btn-primary">Lengkap Data</button> */}
              {/* <Link to="/profile" type="button" className="btn btn-primary">Lebih Detail</Link> */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Index
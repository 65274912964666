import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import { evaluationsKpiPAT } from '../../../utils/data';
import { useNavigate, useLocation } from 'react-router-dom';

const EvaluationAdd = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const path = location.pathname.split('/');
  const id = path[3];

  const [isLoading, setIsloading] = useState(false);
  const [disable, setDisabled] = useState(true);
  const [evaluates, setEvaluates] = useState({
    note: "",
    evaluated: evaluationsKpiPAT,
  });

  useEffect(() => {
    const getEvaluationsPAT = async () => {
      const data = await api.appGetClassificationsPAT();
      const evaluated = data.map((value) => ({
        classificationId: value.classificationId,
        classification: value.classification,
        evaluation: 0,
      }));
      setEvaluates({ ...evaluates, evaluated });
    }

    getEvaluationsPAT();
  }, []);

  const onHandleChangeNilai = ({ evaluation, key }) => {
    if (evaluation > 100) { alert('Nilai tidak boleh lebih dari 100!'); return }
    const newState = {
      catatan: evaluates.note,
      evaluated: [...evaluates?.evaluated]
    };
    newState.evaluated[key].evaluation = evaluation;
    setEvaluates(newState);
    if (newState.evaluated[5].evaluation < 1) {
      setDisabled(true);
    } else {
      setDisabled(false)
    }
  }

  const onHandlePostEvaluate = async () => {
    setIsloading(true);
    const { status, message } = await api.appPostEvaluatePAT({ id, payload: evaluates });
    setTimeout(() => {
      if (status !== 'success') {
        alert(message);
      } else {
        alert(message)
      }
      navigate('/app/evaluation');
      setIsloading(false);
    }, 1000);
  }

  return (
    <div className='row'>
      <div className="col-12 mb-sm-3">
        <div className="shadow-sm">
          <div className="card-body p-3">
            <h1 className="h5">Nilai</h1>
            <div className="row g-3">

              {evaluates?.evaluated?.map((value, key) => (
                <div className="col-12">
                  <div className="row g-3">
                    <div className="col-8">
                      <label htmlFor="Klasifikasi" className="form-label">Indikator {key + 1}</label>
                      <textarea rows={3} disabled value={value.classification} type="text" className="form-control" id="Klasifikasi" />
                    </div>

                    <div className="col-4">
                      <label htmlFor="Nilai" className="form-label">Nilai</label>
                      <input value={evaluates?.evaluated[key]?.evaluation} onChange={(e) => onHandleChangeNilai({ evaluation: e.target.value, key })} type="number" placeholder='0' className="form-control" id="Nilai" min={1} max={100} required />
                    </div>
                  </div>
                </div>
              ))}

              <div className="col-12">
                <label htmlFor="Catatan" className="form-label">Catatan</label>
                <textarea rows={2} value={evaluates?.catatan} onChange={(e) => setEvaluates({ ...evaluates, catatan: e.target.value })} type="text" placeholder='Catatan' className="form-control" id="Catatan" required />
              </div>

              <div className="d-grid gap-2">
                <button disabled={disable || isLoading} className='btn btn-primary' type='button' onClick={() => onHandlePostEvaluate()}>
                  {isLoading ?
                    <>
                      <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                      <span role="status"> Loading...</span>
                    </>
                    :
                    <>
                      <span>Simpan</span>
                    </>
                  }
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EvaluationAdd
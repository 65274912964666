import api from './api';

const loginAction = async ({ email, password }) => {
  const { status, message, data: token } = await api.login({ email, password });

  if (status !== 'success') {
    return { status, message, data: null };
  } else {
    const { accessToken, refreshToken } = token;

    api.putAccessToken(accessToken);
    api.putRefreshToken(refreshToken);

    const authUser = await api.getOwnProfile();
    const { data } = authUser;
    return { status, data };
  }
}

const loginAsEmpBcfAction = async ({ email, password }) => {
  const { status, message, data: token } = await api.loginAsEmpBcf({ email, password });

  if (status !== 'success') {
    return { status, message, data: null };
  } else {
    const { accessToken, refreshToken } = token;

    api.putAccessToken(accessToken);
    api.putRefreshToken(refreshToken);

    const authUser = await api.getOwnProfile();
    const { data } = authUser;
    return { status, data };
  }
}

const loginAsMentorsAction = async ({ email, password }) => {
  const { status, message, data: token } = await api.loginAsMentors({ email, password });

  if (status !== 'success') {
    return { status, message, data: null };
  } else {
    const { accessToken, refreshToken } = token;

    api.putAccessToken(accessToken);
    api.putRefreshToken(refreshToken);

    const authUser = await api.getOwnProfile();
    const { data } = authUser;
    return { status, data };
  }
}

const logoutAction = () => {
  api.deleteToken();
}

const registerAction = async ({ name, email, password }) => {
  const data = await api.register({ name, email, password });
  return data;
}

export {
  loginAction,
  loginAsEmpBcfAction,
  loginAsMentorsAction,
  logoutAction,
  registerAction,
}
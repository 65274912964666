import React, { useState, useEffect, Children } from 'react'
import { Routes, Route, Outlet, useNavigate, Navigate } from 'react-router-dom';
import { logoutAction } from './utils/action';

// Api
import api from './utils/api';

// Components
import Navigation from './Components/Navigation';
import Footer from './Components/Footer';
import Sidebar from './Components/Sidebar';

// Pages

// Update
import UpdateIndex from './Pages/Update/Index';
// import UpdateDiri from './Pages/Update/UpdateDiri';
// import MinatBakatDiri from './Pages/Update/MinatBakatDiri';
// import MediaSosial from './Pages/Update/MediaSosial';
// import Mobilisasi from './Pages/Update/Mobilisasi';
import Profile from './Pages/Update/Profile';

// Publication
import PublicationList from './Pages/Publication/PublicationList';
import PublicationDetail from './Pages/Publication/PublicationDetail';

// App
import AppIndex from './Pages/App/index';
import Login from './Pages/App/Login';
import ProfileApp from './Pages/App/Profile/Index';
import PublicationAppIndex from './Pages/App/Publication/Index';
import PublicationAppAdd from './Pages/App/Publication/PublicationAdd';
import PublicationAppDetail from './Pages/App/Publication/PublicationDetail';
import PublicationAppEdit from './Pages/App/Publication/PublicationEdit';
import UpdateDiriApp from './Pages/App/Profile/UpdateProfile/UpdateDiri';
import UpdateMobilisasiApp from './Pages/App/Profile/UpdateProfile/Mobilisasi';
import UpdateMinatBakatDiriApp from './Pages/App/Profile/UpdateProfile/MinatBakatDiri';
import UpdateMediaSosialApp from './Pages/App/Profile/UpdateProfile/MediaSosial';
import AppEvaluationList from './Pages/App/Evaluation/EvaluationList';
import AppEvaluationDetail from './Pages/App/Evaluation/EvaluationDetail';
import AppEvaluationAdd from './Pages/App/Evaluation/EvaluationAdd';
import AppEvaluationFinal from './Pages/App/Evaluation/EvaluationFinal';

// Admin
import AdminIndex from './Pages/Admin/Index';
import AdminLogin from './Pages/Admin/Login';
import AdminPublicationDetail from './Pages/Admin/Publication/PublicationDetail';
import AdminPublicationList from './Pages/Admin/Publication/PublicationList';
import AdminEvaluationList from './Pages/Admin/Evaluation/EvaluationList';
import AdminEvaluationDetail from './Pages/Admin/Evaluation/EvaluationDetail';
import AdminPATEvaluationDetail from './Pages/Admin/EvaluationPAT/DetaiEvaluated';
import AdminPATHasEvaluationDetail from './Pages/Admin/EvaluationPAT/DetailHasEvaluate';
import AdminEvaluationAdd from './Pages/Admin/Evaluation/EvaluationAdd';
import AdminUsersList from './Pages/Admin/Users/UsersList';
import AdminUsersDetail from './Pages/Admin/Users/UsersDetail';
import MentorsList from './Pages/Admin/Mentors/MentorsList';
import MentorsDetail from './Pages/Admin/Mentors/MentorsDetail';
import MentorsAdd from './Pages/Admin/Mentors/MentorsAdd';
import ClassificationsHardskills from './Pages/Admin/Classifications/ClassificationsHardskills';
import ClassificationsAdd from './Pages/Admin/Classifications/ClassificationsAdd';
import AdminInstitutionsList from './Pages/Admin/Institutions/InstitutionsList';
import AdminInstitutionsDetail from './Pages/Admin/Institutions/Detail';

// Mentors
import MentorsIndex from './Pages/Mentors/index';
import MentorsLogin from './Pages/Mentors/Login';
import MentorsEvaluationList from './Pages/Mentors/Evaluation/EvaluationList';
import MentorsEvaluationDetail from './Pages/Mentors/Evaluation/EvaluationDetail';
import MentorsEvaluationAdd from './Pages/Mentors/Evaluation/EvaluationAdd';
import MentorsClassificationsHardskills from './Pages/Mentors/Classifications/ClassificationsHardskills';
import MentorsClassificationsAdd from './Pages/Mentors/Classifications/ClassificationsAdd';
import MentorsPATEvaluationDetail from './Pages/Mentors/EvaluationPAT/DetaiEvaluated';

function App() {
  const navigate = useNavigate();
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    const asyncPreloadProcess = async () => {
      const { data: authUsers } = await api.getOwnProfile();
      const offeredProgram = await api.getOwnOfferedProgram();
      const data = {
        ...authUsers,
        offeredProgram,
      }
      setAuthUser(data);
    }

    asyncPreloadProcess();
  }, []);

  const onSignOut = () => {
    navigate('/')
    setAuthUser(null);
    logoutAction();
  }

  const ProtectedRoute = ({ authUser, children }) => {
    if (!authUser || authUser?.currentlyProgram.role.roleName !== 'Mahasiswa') {
      return <Navigate to="/app/login" replace />
    }
    return children ? children : <Outlet />
  }

  const ProtectedRouteAdmin = ({ authUser, children }) => {
    if (!authUser || authUser?.currentlyProgram.role.roleName !== 'Admin') {
      return <Navigate to="/admin/login" replace />
    }
    return children ? children : <Outlet />
  }

  const ProtectedRouteMentors = ({ authUser, children }) => {
    if (!authUser || authUser?.currentlyProgram.role.roleName !== 'Mentors') {
      return <Navigate to="/mentors/login" replace />
    }
    return children ? children : <Outlet />
  }

  const LoggedInRoute = ({ authUser, children }) => {
    if (authUser && authUser?.role === 'Users') {
      return <Navigate to="/app" replace />
    }
    return children ? children : <Outlet />
  }

  const LoggedInRouteAdmin = ({ authUser, children }) => {
    if (authUser && authUser?.role === 'Admin') {
      return <Navigate to="/admin" replace />
    }
    return children ? children : <Outlet />
  }

  const LoggedInRouteMentors = ({ authUser, children }) => {
    if (authUser && authUser?.role === 'Mentors') {
      return <Navigate to="/mentors" replace />
    }
    return children ? children : <Outlet />
  }

  const AppLayout = () => (
    <div className='container mt-5'>
      <Outlet />
    </div>
  )

  const AdminLayout = ({ authUser, signOut }) => (
    <div className='row'>
      <div className="col-3">
        <Sidebar authUser={authUser} signOut={signOut} />
      </div>
      <div className="col-9 p-5">
        <Outlet />
      </div>
    </div>
  )

  const MentorsLayout = ({ authUser, signOut }) => (
    <div className='row'>
      <div className="col-3">
        <Sidebar authUser={authUser} signOut={signOut} />
      </div>
      <div className="col-9 p-5">
        <Outlet />
      </div>
    </div>
  )

  return (
    <>
      <header>
        <Navigation authUser={authUser} setAuthUser={setAuthUser} signOut={onSignOut} />
      </header>
      <main>
        <Routes>
          <Route path='/' element={<AppLayout />}>
            <Route index element={<Login setAuthUser={setAuthUser} />} />
            {/* <Route path='update'>
              <Route index element={<UpdateIndex />} />
              <Route path='profile' element={<Profile />} />
              <Route path='diri' element={<UpdateDiri />} />
              <Route path='mobilisasi' element={<Mobilisasi />} />
              <Route path='minat' element={<MinatBakatDiri />} />
              <Route path='sosmed' element={<MediaSosial />} />
            </Route> */}

            <Route path='publication'>
              <Route index element={<PublicationList />} />
              <Route path=':slug' element={<PublicationDetail />} />
            </Route>

            <Route path='app'>
              {/* <Route index element={<AppIndex />} /> */}
              <Route element={<LoggedInRoute authUser={authUser} />}>
                <Route path='login' element={<Login setAuthUser={setAuthUser} />} />
              </Route>
              <Route element={<ProtectedRoute authUser={authUser} />}>
                <Route index element={<ProfileApp authUser={authUser} />} />
                <Route path='profile'>
                  <Route index element={<ProfileApp authUser={authUser} />} />
                  <Route path='update'>
                    <Route path='identitas' element={<UpdateDiriApp />} />
                    <Route path='mobilisasi' element={<UpdateMobilisasiApp />} />
                    <Route path='interest-talent' element={<UpdateMinatBakatDiriApp />} />
                    <Route path='social-media' element={<UpdateMediaSosialApp />} />
                  </Route>
                </Route>
                <Route path='publication'>
                  <Route index element={<PublicationAppIndex />} />
                  <Route path='add' element={<PublicationAppAdd />} />
                  <Route path=':id/edit' element={<PublicationAppEdit />} />
                  <Route path=':id' element={<PublicationAppDetail />} />
                </Route>
                <Route path='evaluation'>
                  <Route index element={<AppEvaluationList authUser={authUser} />} />
                  <Route path=':id' element={<AppEvaluationDetail />} />
                  <Route path=':id/evaluate' element={<AppEvaluationAdd />} />
                  <Route path='final' element={<AppEvaluationFinal authUser={authUser} />} />
                </Route>
              </Route>
            </Route>
          </Route>

          <Route path='admin'>
            <Route element={<LoggedInRouteAdmin authUser={authUser} />}>
              <Route path='login' element={<AdminLogin setAuthUser={setAuthUser} />} />
            </Route>
            <Route element={<ProtectedRouteAdmin authUser={authUser} />} >
              <Route element={<AdminLayout authUser={authUser} signOut={onSignOut} />}>
                <Route index element={<AdminIndex />} />
                <Route path='publication'>
                  <Route index element={<AdminPublicationList />} />
                  <Route path=':id' element={<AdminPublicationDetail />} />
                </Route>
                <Route path='evaluation'>
                  <Route index element={<AdminEvaluationList />} />
                  <Route path=':id'>
                    <Route index element={<AdminEvaluationDetail />} />
                    <Route path='pat/evaluated' element={<AdminPATHasEvaluationDetail />} />
                    <Route path='pat' element={<AdminPATEvaluationDetail />} />
                  </Route>
                  <Route path=':id/evaluate' element={<AdminEvaluationAdd />} />
                </Route>
                <Route path='classifications'>
                  <Route index element={<ClassificationsHardskills />} />
                  <Route path='add' element={<ClassificationsAdd />} />
                </Route>
                <Route path='users'>
                  <Route index element={<AdminUsersList />} />
                  <Route path=':id' element={<AdminUsersDetail />} />
                </Route>
                <Route path='mentors'>
                  <Route index element={<MentorsList />} />
                  <Route path='add' element={<MentorsAdd />} />
                  <Route path=':id' element={<MentorsDetail />} />
                </Route>
                <Route path='institutions'>
                  <Route index element={<AdminInstitutionsList />} />
                  <Route path=':id' element={<AdminInstitutionsDetail />} />
                </Route>
              </Route>
            </Route>
          </Route>

          <Route path='mentors'>
            <Route element={<LoggedInRouteMentors authUser={authUser} />}>
              <Route path='login' element={<MentorsLogin setAuthUser={setAuthUser} />} />
            </Route>
            <Route element={<ProtectedRouteMentors authUser={authUser} />} >
              <Route element={<MentorsLayout authUser={authUser} signOut={onSignOut} />}>
                <Route index element={<MentorsIndex />} />
                <Route path='evaluation'>
                  <Route index element={<MentorsEvaluationList />} />
                  <Route path=':id'>
                    <Route index element={<MentorsEvaluationDetail />} />
                    <Route path='evaluate/:divisiId' element={<MentorsEvaluationAdd />} />
                    <Route path='pat' element={<MentorsPATEvaluationDetail />} />
                  </Route>
                </Route>
                <Route path='classifications'>
                  <Route index element={<MentorsClassificationsHardskills />} />
                  <Route path='add' element={<MentorsClassificationsAdd />} />
                </Route>
              </Route>
            </Route>
          </Route>

          <Route path='*' element={
            <h1 className='h5 text-center m-5'>Halaman tidak ditemukan</h1>
          } />
        </Routes>
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
}

export default App;

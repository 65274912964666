import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../../utils/api';

const Detail = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const path = location.pathname.split('/');
  const id = path[3];

  const [data, setData] = useState({});

  useEffect(() => {
    const getEvaluationsPATById = async () => {
      const data = await api.adminGetEvaluatesPATById({ id });
      setData(data);
    }

    getEvaluationsPATById();
  }, [id]);

  return (
    <div className="row g-3">
      <div className='shadow-sm'>
        <div className='card-body m-3'>
          <div className="d-flex justify-content-between mb-3">
            <h1 className="h5">Penilaian Antar Teman</h1>
            <div className="col-2">
            </div>
          </div>
          <div className="row g-3">
            {data?.evaluations?.length < 1 ?
              <div class="alert alert-warning alert-dismissible fade show text-center" role="alert">
                <strong>Teman sedivisi belum menilai!</strong>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>
              :
              <>
                {data?.evaluations?.map((friend, key) => (
                  <div key={key} className="col-12">
                    <div key={key} className="row g-3">

                      <div className="col-12">
                        <label htmlFor="Nama" className="form-label">Nama</label>
                        <div className="input-group has-validation">
                          <input disabled value={friend?.fullname} type="text" className="form-control" id="Nama" placeholder="Nama" />
                        </div>
                      </div>

                      <div className="col-12">
                        <label htmlFor="Nilai Total" className="form-label">Nilai Total</label>
                        <div className="input-group has-validation">
                          <input disabled value={friend?.nilai_total} type="text" className="form-control" id="Nilai Total" placeholder="Nilai Total" />
                        </div>
                      </div>

                      <div className="col-12">
                        <label htmlFor="Rata Rata Nilai" className="form-label">Rata Rata Nilai</label>
                        <div className="input-group has-validation">
                          <input disabled value={friend?.rata_rata_nilai} type="text" className="form-control" id="Rata Rata Nilai" placeholder="Rata Rata Nilai" />
                        </div>
                      </div>

                      <div className="col-12">
                        <label htmlFor="Catatan" className="form-label">Catatan</label>
                        <div className="input-group has-validation">
                          <input disabled value={friend?.catatan} type="text" className="form-control" id="Catatan" placeholder="Catatan" />
                        </div>
                      </div>

                      <div className="col-12 table-responsive">
                        <table className='table table-hover'>
                          <thead>
                            <tr>
                              <th scope="col">#</th>
                              <th scope="col">Klasifikasi</th>
                              <th scope="col">Nilai</th>
                            </tr>
                          </thead>
                          <tbody>
                            {friend?.result?.map((value, key) => (
                              <tr key={key} onClick={() => navigate(`/admin/users/${value?.id}`)} style={{ cursor: 'pointer' }}>
                                <th scope="row">{key + 1}</th>
                                <td>{value?.klasifikasi}</td>
                                <td>{value?.nilai}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            }
          </div>
        </div>

      </div>
    </div>
  )
}

export default Detail;

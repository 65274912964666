import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import { useLocation, useNavigate } from 'react-router-dom';

const Detail = () => {
  const [institutions, setInstitutions] = useState();

  const location = useLocation();
  const navigate = useNavigate();

  const path = location.pathname.split('/');
  const id = path[3];

  useEffect(() => {
    const getInstitutionsById = async () => {
      const data = await api.adminGetInstitutionsById({ id });
      setInstitutions(data);
      console.log(data);
    }

    getInstitutionsById();
  }, [id]);

  const onHandleDeleteMentors = async () => {
    const confirm = window.confirm('Yakin ingin menghapus mentor?');
    if (confirm) {
      await api.adminDeleteMentorsById({ id });
      navigate('/admin/mentors')
    }
  }

  return (
    <div className="row g-3">
      <div className='shadow-sm'>
        <div className='card-body m-3'>
          <div className="d-flex justify-content-between mb-3">
            <h1 className="h5">Lembaga</h1>
            <div className="col-2">
              {/* <div className="d-grid gap-2">
                <button onClick={() => onHandleDeleteMentors()} className='btn btn-danger'>Hapus</button>
              </div> */}
            </div>
          </div>

          <div className="row g-3">
            <div className="col-12">
              <label htmlFor="Nama" className="form-label">Nama</label>
              <div className="input-group has-validation">
                <input disabled value={institutions?.name} type="text" className="form-control" id="Nama" placeholder="Nama" required />
              </div>
            </div>

            {institutions?.divisions?.map((division, key) => (
              <div key={key} className="col-12">
                <div key={key} className="row g-3">
                  <div className="col-12">
                    <label htmlFor="Divisi" className="form-label">Divisi</label>
                    <div className="input-group has-validation">
                      <input disabled value={division?.name} type="text" className="form-control" id="Divisi" placeholder="Divisi" />
                    </div>
                  </div>

                  <div className="col-12 table-responsive">
                    <table className='table table-hover'>
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Nama</th>
                          <th scope="col">Universitas</th>
                          <th scope="col">Semester</th>
                        </tr>
                      </thead>
                      <tbody>
                        {division?.users?.map((user, key) => (
                          <tr key={key} onClick={() => navigate(`/admin/users/${user.id}`)} style={{ cursor: 'pointer' }}>
                            <th scope="row">{key + 1}</th>
                            <td>{user.fullname}</td>
                            <td>{user.universities}</td>
                            <td>{user.semester}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ))}

          </div>

        </div>
      </div>
    </div>
  )
}

export default Detail;

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const MinatBakatDiri = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState({});
  const location = useLocation();
  const { user } = location.state;

  const [skillCheck, setSkillCheck] = useState(true);
  const [skillOthers, setSkillOthers] = useState('');
  const [skills, setSkills] = useState([
    'Menyanyi',
    'Menari',
    'Membawakan Acara (MC)',
    'Membawakan Acara (Moderator)',
    'Menerjemakan ke dalam Bahasa Inggris',
    'Membuat Karya Tulis Ilmiah',
    'Memotret/Fotografi',
    'Membuat Desain',
    'Mengedit Video',
    'Make-Up',
    'Melukis/Menggambar',
    'Story Telling',
    'Pidato',
    'Stand Up Comedy',
  ]);

  const [musicsCheck, setMusicsCheck] = useState(true);
  const [musicsOthers, setMusicsOthers] = useState('');
  const [musics, setMusics] = useState([
    'Alat Musik Tradisional',
    'Gitar',
    'Gitar Akustik',
    'Piano',
    'Violin/Biola',
    'Drum',
    'Flute/seruling',
    'Saxophone',
    'Keyboard',
    'Alat Musik Elektrik',
    'Cello',
    'Harmonika',
    'Akordion',
    'Ukulele',
    'Trompet',
  ]);

  const [bcfAct, setBcfAct] = useState([
    'Seminar Nasional',
    'Seminar Internasional',
    'Ikatan Alumni Home of Leaders',
    'Magang Offline',
    'Magang Online',
    'Volunteer',
    'Penelitian',
    'Pengabdian kepada Masyarakat',
  ]);

  // Skills State Management

  const [selectedSkills, setSelectedSkills] = useState([]);
  const onHandleSelectedSkills = (e) => {
    const { value } = e.target;
    let newArray = [...selectedSkills, value];
    if (selectedSkills.includes(value)) {
      newArray = newArray.filter(skill => skill !== value);
    }
    setSelectedSkills(newArray)
  }

  const onHandleSkillChecked = (e) => {
    const { checked } = e.target;
    setSkillCheck(!checked);
    let newArray = [...selectedSkills];
    if (checked === true) {
      if (selectedSkills.includes(skillOthers)) {
        newArray = newArray.filter(skill => skill !== skillOthers)
      }
    }
    // else {
    //   newArray.push(skillOthers)
    // }
    setSelectedSkills(newArray);
  }

  const onHandleOtherSaveSkill = () => {
    setSkillCheck(true);
    setSelectedSkills([...selectedSkills, skillOthers])
  }

  // Musics State Management

  const [selectedMusics, setSelectedMusics] = useState([]);
  const onHandleSelectedMusics = (e) => {
    const { value } = e.target;
    let newArray = [...selectedMusics, value];
    if (selectedMusics.includes(value)) {
      newArray = newArray.filter(music => music !== value);
    }
    setSelectedMusics(newArray)
  }

  const onHandleMusicsChecked = (e) => {
    const { checked } = e.target;
    setMusicsCheck(!checked);
    let newArray = [...selectedMusics];
    if (checked === true) {
      if (selectedMusics.includes(musicsOthers)) {
        newArray = newArray.filter(music => music !== musicsOthers)
      }
    }
    setSelectedMusics(newArray);
  }

  const onHandleOtherSaveMusics = () => {
    setMusicsCheck(true);
    setSelectedMusics([...selectedMusics, musicsOthers])
  }

  // BCF Activity State Management

  const [selectedBcfAct, setSelectedBcfAct] = useState([]);
  const onHandleSelectedBcfAct = (e) => {
    const { value } = e.target;
    let newArray = [...selectedBcfAct, value];
    if (selectedBcfAct.includes(value)) {
      newArray = newArray.filter(bcfAct => bcfAct !== value);
    }
    setSelectedBcfAct(newArray)
  }

  useEffect(() => {
    setProfile(user)

    setSelectedSkills(user?.kemampuan?.split(","));
    setSelectedMusics(user?.alat_musik?.split(","));
    setSelectedBcfAct(user?.kegiatan_bcf?.split(","));
  }, []);

  const onHandleSubmit = () => {
    const assingn = Object.assign(user, profile);

    const newSelectedSkills = selectedSkills.filter(skill => skill !== '');
    const newSelectedMusics = selectedMusics.filter(music => music !== '');
    const newSelectedBcfAct = selectedBcfAct.filter(bcfAct => bcfAct !== '');

    const data = {
      ...assingn,
      kemampuan: newSelectedSkills.toString(),
      alat_musik: newSelectedMusics.toString(),
      kegiatan_bcf: newSelectedBcfAct.toString(),
    }

    const {
      kemampuan,
      alat_musik,
      deskripsi_kemampuan,
      penghargaan_5_tahun,
      kegiatan_bcf,
      kesibukan_lain,
      rencana_5_tahun,
      deskripsi_rencana_5_tahun,
      foto_diri_id_card,
    } = data;

    if (kemampuan === "") return alert('Kemampuan Kosong!')
    if (alat_musik === "") return alert('Alat Musik Kosong!')
    if (deskripsi_kemampuan === "") return alert('Deskripsi Kemampuan Kosong!')
    if (penghargaan_5_tahun === "") return alert('Penghargaan 5 Tahun Kosong!')
    if (kegiatan_bcf === "") return alert('Kegiatan BCF Kosong!')
    if (kesibukan_lain === "") return alert('Kesibukan Lain Kosong!')
    if (rencana_5_tahun === "") return alert('Rencana 5 Tahun Kosong!')
    if (deskripsi_rencana_5_tahun === "") return alert('Deskripsi Rencana 5 Tahun Kosong!')
    if (foto_diri_id_card === "") return alert('Foto Diri ID Card Kosong!')

    navigate('/app/profile/update/social-media', { state: { user: data } })
  }

  return (
    <>
      <div className="py-5 text-center">
        <h2>III. MINAT DAN BAKAT MAHASISWA</h2>
        <p className="lead">Bagian ini merupakan pendataan minat dan bakat yang dimiliki mahasiswa untuk bisa diwadahi melalui kegiatan-kegiatan yang BCF sediakan selama periode Magang/Praktikum/Volunteer maupun kesempatan setelah mahasiswa menjadi alumni di Ikatan Alumni BCF yang disebut Home of Leaders yang dapat dipelajari di sini: https://bit.ly/IkatanAlumniBCF2023 Isi bagian Minat dan Bakat dengan JUJUR dan BENAR!</p>
      </div>

      <div className="row g-5 mb-5">
        <div className="col-md-12 col-lg-8">
          <h4 className="mb-3">Minat Dan Bakat Mahasiswa</h4>

          <form className="needs-validation" noValidate="">

            <div className="row g-3">
              <div className="shadow-sm">
                <div className="card-body m-3">
                  <div className="col-12">
                    <label htmlFor="address" className="form-label">Kemampuan Apa yang Kamu Miliki?</label>
                    {skills.map((skill, key) => (
                      <div className="form-check" key={key}>
                        <input checked={selectedSkills?.includes(skill)} onChange={(e) => onHandleSelectedSkills(e)} className="form-check-input" type="checkbox" value={`${skill}`} id={`check-${skill}`} />
                        <label className="form-check-label" htmlFor={`check-${skill}`}>
                          {skill}
                        </label>
                      </div>
                    ))}
                    <div className="input-group mt-2">
                      <div className="input-group-text">
                        <input checked={!skillCheck} onChange={(e) => onHandleSkillChecked(e)} className="form-check-input mt-0" type="checkbox" value="" aria-label="Checkbox for following text input" />
                      </div>
                      <input disabled={skillCheck} value={skillOthers} onChange={(e) => setSkillOthers(e.target.value)} type="text" placeholder='Lainnya' className="form-control" aria-describedby="button-addon2" aria-label="Text input with checkbox" />
                      <button disabled={skillCheck} onClick={() => onHandleOtherSaveSkill()} className="btn btn-outline-secondary" type="button" id="button-addon2">Simpan</button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="shadow-sm">
                <div className="card-body m-3">
                  <div className="col-12">
                    <label htmlFor="address" className="form-label">Alat Musik Apa yang Dapat Kamu Mainkan?</label>
                    {musics.map((music, key) => (
                      <div className="form-check" key={key}>
                        <input checked={selectedMusics?.includes(music)} onChange={(e) => onHandleSelectedMusics(e)} className="form-check-input" type="checkbox" value={`${music}`} id={`check-${music}`} />
                        <label className="form-check-label" htmlFor={`check-${music}`}>
                          {music}
                        </label>
                      </div>
                    ))}
                    <div className="input-group mt-2">
                      <div className="input-group-text">
                        <input checked={!musicsCheck} onChange={(e) => onHandleMusicsChecked(e)} className="form-check-input mt-0" type="checkbox" value="" aria-label="Checkbox for following text input" />
                      </div>
                      <input disabled={musicsCheck} value={musicsOthers} onChange={(e) => setMusicsOthers(e.target.value)} type="text" placeholder='Lainnya' className="form-control" aria-describedby="button-addon2" aria-label="Text input with checkbox" />
                      <button disabled={musicsCheck} onClick={() => onHandleOtherSaveMusics()} className="btn btn-outline-secondary" type="button" id="button-addon2">Simpan</button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="shadow-sm">
                <div className="card-body p-3">

                  <div class="alert alert-primary d-flex align-items-center" role="alert">
                    <div>
                      <p>Pengisian Data tidak diperbolehkan menggunakan emoticon seperti: (🙏🔥👌)</p>
                    </div>
                  </div>

                  <div className="row g-3">
                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Dekripsikan Pengalaman terkait Bakat/Kemampuan yang Kamu Miliki (Contoh: pernah menyanyi rutin di kafe) </label>
                      <input onChange={(e) => setProfile({ ...profile, deskripsi_kemampuan: e.target.value })} value={profile?.deskripsi_kemampuan} type="text" className="form-control" id="address" required="" />
                      <div className="invalid-feedback">
                        Please enter your shipping address.
                      </div>
                    </div>

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Dalam kurun waktu 5 tahun terakhir, apakah kamu pernah mendapatkan penghargaan yang berkaitan dengan Bakat/Kemampuan yang kamu miliki? (Contoh: Juara 1 menari tingkat RT)</label>
                      <input onChange={(e) => setProfile({ ...profile, penghargaan_5_tahun: e.target.value })} value={profile?.penghargaan_5_tahun} type="text" className="form-control" id="address" required="" />
                      <div className="invalid-feedback">
                        Please enter your shipping address.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="shadow-sm">
                <div className="card-body p-3">
                  <div className="col-12">
                    <label htmlFor="address" className="form-label">Kegiatan-kegiatan BCF Mana Saja yang Kamu Minati?</label>
                    {bcfAct.map((act, key) => (
                      <div className="form-check" key={key}>
                        <input checked={selectedBcfAct?.includes(act)} onChange={(e) => onHandleSelectedBcfAct(e)} className="form-check-input" type="checkbox" value={`${act}`} id={`check-${act}`} />
                        <label className="form-check-label" htmlFor={`check-${act}`}>
                          {act}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="shadow-sm">
                <div className="card-body p-3">

                  <div class="alert alert-primary d-flex align-items-center" role="alert">
                    <div>
                      <p>Pengisian Data tidak diperbolehkan menggunakan emoticon seperti: (🙏🔥👌)</p>
                    </div>
                  </div>

                  <div className="row g-3">
                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Apakah kamu memiliki kesibukan lain saat ini? Silakan sebutkan dan jelaskan secara singkat.</label>
                      <input onChange={(e) => setProfile({ ...profile, kesibukan_lain: e.target.value })} value={profile?.kesibukan_lain} type="text" className="form-control" id="address" required="" />
                      <div className="invalid-feedback">
                        Please enter your shipping address.
                      </div>
                    </div>

                    <div className="col-12">
                      <label htmlFor="country" className="form-label">Apakah rencanamu dalam waku 5 tahun ke depan, dalam aspek karir maupun pendidikan?</label>
                      <select value={profile?.rencana_5_tahun} onChange={(e) => setProfile({ ...profile, rencana_5_tahun: e.target.value })} className="form-select" id="country" required="">
                        <option defaultValue>Pilih</option>
                        <option value="Melanjutkan pendidikan">Melanjutkan pendidikan</option>
                        <option value="Mengembangkan usaha bisnis (maupun bisnis sosial)">Mengembangkan usaha bisnis (maupun bisnis sosial)</option>
                        <option value="Membentuk gerakan atau komunitas sosial">Membentuk gerakan atau komunitas sosial</option>
                        <option value="Lainnya">Lainnya</option>
                      </select>
                      <div className="invalid-feedback">
                        Please select a valid country.
                      </div>
                    </div>

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Ceritakan secara singkat mengenai rencanamu ke depan sesuai dengan pilihan yang kamu isi pada pertanyaan sebelumnya.</label>
                      <input onChange={(e) => setProfile({ ...profile, deskripsi_rencana_5_tahun: e.target.value })} value={profile?.deskripsi_rencana_5_tahun} type="text" className="form-control" id="address" required="" />
                      <div className="invalid-feedback">
                        Please enter your shipping address.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="shadow-sm">
                <div className="card-body p-3">

                  <div className="col-12">
                    <label htmlFor="address" className="form-label">Foto Diri Mahasiswa untuk ID Card (Link Google Drive)</label>
                    <input onChange={(e) => setProfile({ ...profile, foto_diri_id_card: e.target.value })} value={profile?.foto_diri_id_card} type="text" className="form-control" id="address" required="" />
                    <div className="invalid-feedback">
                      Please enter your shipping address.
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <button onClick={() => onHandleSubmit()} className="w-100 btn btn-primary btn-lg my-4" type="button">Lanjut</button>
          </form>
        </div>
      </div>
    </>
  )
}

export default MinatBakatDiri
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import api from '../../../utils/api';

const Index = () => {
  const [data, setData] = useState();
  useEffect(() => {
    const getPublications = async () => {
      const data = await api.getPublicationsApp({ pageSize: 10, page: 1 });
      setData(data.publications);
    }

    getPublications();
  }, []);

  return (
    <div className='row'>
      <div className='col-md-6'>
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between mb-3">
              <h1 className='h5'>Publikasi</h1>
              <Link to="/app/publication/add" className='btn btn-primary text-light'>Tambah</Link>
            </div>
            <div className="row g-3">
              {!data?.length > 0 &&
                <p className='text-muted text-center'>Kamu belum menambah publikasi</p>
              }

              {data?.map((publication, key) => (
                <div key={key} class="col-12">
                  <div class="card">
                    {publication.isDraft === 1 && <>
                      <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning">
                        <span class="visually-hidden">unread messages</span>
                        Draft
                      </span>
                    </>}

                    <div class="card-body">
                      <Link to={`/app/publication/${publication.id}`} class="card-title h5 stretched-link text-decoration-none">{publication.title}</Link>
                      <p class="card-text">{publication.abstract}</p>
                      {publication?.topic?.split(",").map((e) => (
                        <span class="badge rounded-pill text-bg-light">{e}</span>
                      ))}
                    </div>
                  </div>
                </div>
              ))}

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index
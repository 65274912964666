import React from 'react';
import * as bootstrap from 'bootstrap';

const ToastNotifications = ({ setShowNotifications, show, message }) => {
  const toastLiveExample = document.getElementById('liveToast')
  const toastBootstrap = bootstrap.Toast.getOrCreateInstance(toastLiveExample)

  if (show) {
    toastBootstrap.show();
    setShowNotifications(false);
  }

  return (
    <div class="toast-container position-fixed bottom-0 end-0 p-3">
      <div id="liveToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <img src="/favicon.ico" style={{ width: 30, height: 30, objectFit: 'scale-down' }} class="rounded me-2" alt="..." />
          <strong class="me-auto">Info</strong>
          <small>just now</small>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
          {message}
        </div>
      </div>
    </div>
  )
}

export default ToastNotifications;

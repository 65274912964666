import React, { useState, useEffect } from 'react';
import api from '../../utils/api';

const Index = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const getDashboard = async () => {
      const data = await api.getDashboardAdmin();
      console.log(data);
      setData(data);
    }

    getDashboard();
  }, []);

  return (
    <div className='row'>

      <div className='col-12'>
        <div className="card">
          <div className="card-body">
            <h1 className='h5'>Publikasi</h1>
            <div className="d-flex justify-content-between">
              <h2 className='h6'>Total Pengguna: <span class="badge text-bg-secondary">{data.totalUsers}</span></h2>
              <h2 className='h6'>Total Publikasi: <span class="badge text-bg-secondary">{data.totalPublications}</span></h2>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Index
const evaluationsKpiPAT = [
  {
    "classificationId": 1,
    "classification": "Berikan penilaian terhadap teman sekelompok Kamu dalam aspek Kontribusi Akademik (sejauh mana ia aktif mencari informasi/bacaan ilmiah/ membaca/ikut aktif berdiskusi/aktif memberikan pandangan/analisis)."
  },
  {
    "classificationId": 2,
    "classification": "Berikan penilaian terhadap teman sekelompok Kamu dalam aspek Kontribusi Non-Akademik (sejauh mana ia aktif membantu penyelesaian tugas dalam bentuk ketrampilan teknis misalnya mengetik tugas, membuat presentasi dengan aplikasi power point, membuatkan ilustrasi."
  },
  {
    "classificationId": 3,
    "classification": "Berkan penilaian terhadap teman sekelompok Kamu dalam aspek Kontribusi berbentuk soft skill (sejauh mana ia aktif mendorong/memotivasi kelompok untuk bekerja sama dengan baik, dan berkomunikasi secara positif)."
  },
  {
    "classificationId": 4,
    "classification": "Seberapa sering teman sekelompok Kamu hadir selama kegiatan berlangsung?"
  },
  {
    "classificationId": 5,
    "classification": "Apakah teman sekelompok Kamu berhak mendapatkan penilaian kegiatan, konversi SKS (bagi yang mendaftar program konversi), sertifikat, eksposur di media sosial, dan surat rekomendasi kerja dari Bakrie Center Foundation?"
  },
  {
    "classificationId": 6,
    "classification": "Apakah teman sekelompok Kamu berhak mendapatkan dana dukungan mahasiswa?"
  }
];

const evaluationsKpiBCF = [
  {
    id: 1,
    klasifikasi: "Misi"
  },
  {
    id: 2,
    klasifikasi: "Kehadiran"
  },
  {
    id: 3,
    klasifikasi: "Kerjasama & Keaktifan"
  },
  {
    id: 4,
    klasifikasi: "Paper Konferensi"
  },
  {
    id: 5,
    klasifikasi: "Kuis"
  }
];

const evaluationsKpiSoftskill = [
  {
    id: 1,
    klasifikasi: "Berkomunikasi yang Baik"
  },
  {
    id: 2,
    klasifikasi: "Bekerjasama dalam Tim"
  },
  {
    id: 3,
    klasifikasi: "Toleransi terhadap Perbedaan"
  },
  {
    id: 4,
    klasifikasi: "Peduli Terhadap Kesusahan Orang Lain"
  },
  {
    id: 5,
    klasifikasi: "Peka Terhadap Situasi Sekitar"
  }
]

const evaluationsKpiHardskill = [
  {
    id: 1,
    klasifikasi: "Berkomunikasi yang Baik"
  },
  {
    id: 2,
    klasifikasi: "Bekerjasama dalam Tim"
  },
  {
    id: 3,
    klasifikasi: "Toleransi terhadap Perbedaan"
  },
  {
    id: 4,
    klasifikasi: "Peduli Terhadap Kesusahan Orang Lain"
  },
  {
    id: 5,
    klasifikasi: "Peka Terhadap Situasi Sekitar"
  }
];

const listFriendsPAT = [
  {
    "usersClPId": 36,
    "firstName": "MUHAMAD",
    "lastName": "GHOZI WIJAYA",
    "hasEvaluated": true
  },
  {
    "usersClPId": 37,
    "firstName": "Ahmed",
    "lastName": "Nizhan Haikal",
    "hasEvaluated": true
  },
  {
    "usersClPId": 39,
    "firstName": "MUHAMAD",
    "lastName": "FAUZAN IBNU SYAHLAN",
    "hasEvaluated": true
  },
  {
    "usersClPId": 40,
    "firstName": "Rayhan",
    "lastName": "Dika Pratama",
    "hasEvaluated": true
  }
]

const finalEvaluation = {
  "mentessId": 0,
  "mentessFirstName": "",
  "mentessLastName": "",
  "finalEvaluationNote": "",
  "finalEvaluationUrlCertificate": "",
  "finalEvaluationTotal": 0,
  "finalEvaluationAverage": 0,
  "mentors": {
    "mentorsId": 0,
    "firstName": "",
    "lastName": ""
  },
  "institutions": {
    "institutionsName": "",
    "institutionsLocation": "",
    "institutionsDivision": "",
    "institutionsClusterFocus": ""
  },
  "universities": {
    "universitiesName": "",
    "universitiesType": "",
    "universitiesMajor": "",
    "universitiesProvinces": "",
    "universitiesRegencies": ""
  },
  "evaluationsBCF": [
    {
      "evaluation": 0,
      "note": "",
      "classification": ""
    },
    {
      "evaluation": 0,
      "note": "",
      "classification": ""
    },
    {
      "evaluation": 0,
      "note": "",
      "classification": ""
    },
    {
      "evaluation": 79,
      "note": "",
      "classification": ""
    },
    {
      "evaluation": 82,
      "note": "",
      "classification": ""
    }
  ],
  "evaluationsSS": [
    {
      "evaluation": 0,
      "note": "",
      "classification": ""
    },
    {
      "evaluation": 0,
      "note": "",
      "classification": ""
    },
    {
      "evaluation": 0,
      "note": "",
      "classification": ""
    },
    {
      "evaluation": 0,
      "note": "",
      "classification": ""
    },
    {
      "evaluation": 0,
      "note": "",
      "classification": ""
    }
  ],
  "evaluationsHS": [
    {
      "evaluation": 0,
      "note": "",
      "classification": ""
    },
    {
      "evaluation": 0,
      "note": "",
      "classification": ""
    },
    {
      "evaluation": 0,
      "note": "",
      "classification": ""
    },
    {
      "evaluation": 0,
      "note": "You managed to work with the back-end team to be able to integrate data into the front-end that has been built, including the registration menu and feedback using Javascript-based Frameworks (React, Sass, and Tanstack: Router, Query and Tables). This indicates that you show a high commitment with the team to release the quality of the lead application that suits the needs of users both from its functionality and non-functionality.",
      "classification": "Implementasi Object Relational Mapping (ORM )"
    },
    {
      "evaluation": 100,
      "note": "As a front-end (FE) web developer, you have successfully translated  the design created by product's team into JavaScript Framework for FE that encompassed React Bootstrap and SASS (CSS's superset) to ensure the page adapts seamlessly to different screen sizes resolutions (responsive web). Moreover, you have managed to build the front-end for admin dashboard, users, mentors, tasks, settings and program information to elevate LEAD program.",
      "classification": "Implementasi REST API"
    }
  ]
}

export {
  evaluationsKpiPAT,
  evaluationsKpiBCF,
  evaluationsKpiSoftskill,
  evaluationsKpiHardskill,
  listFriendsPAT,
  finalEvaluation,
}
import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import { useLocation, useNavigate } from 'react-router-dom';

const PublicationEdit = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const path = location.pathname.split('/');
  const id = path[3];

  const [publication, setPublication] = useState({});
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    const getPublicationsForEditById = async () => {
      const data = await api.getPublicationsForUpdateById({ id });
      setPublication(data);
    }

    const getTopics = async () => {
      const topics = await api.getTopics();
      setTopics(topics);
    }

    getPublicationsForEditById();
    getTopics()
  }, [id]);


  const onHandlerEdit = async () => {
    const message = await api.updatePublications({ id, payload: publication });
    alert(message);
    navigate(`/app/publication/${id}`);
  }

  return (
    <div className="row">

      <div className="col-md-8 mb-sm-3">
        <div className='shadow-sm'>
          <div className='card-body p-3'>
            <div className="row g-3">

              <div className="col-12">
                <label htmlFor="address" className="form-label">Title</label>
                <input value={publication.title} onChange={(e) => setPublication({ ...publication, title: e.target.value })} type="text" className="form-control" id="address" required />
                <div className="invalid-feedback">
                  Please enter your shipping address.
                </div>
              </div>

              <div className="col-12">
                <label htmlFor="address" className="form-label">Abstract</label>
                <textarea value={publication.abstract} onChange={(e) => setPublication({ ...publication, abstract: e.target.value })} rows={4} type="text" className="form-control" id="address" required />
                <div className="invalid-feedback">
                  Please enter your shipping address.
                </div>
              </div>

              <div className="col-12">
                <label htmlFor="address" className="form-label">Url Image</label>
                <input value={publication.url_image} onChange={(e) => setPublication({ ...publication, url_image: e.target.value })} type="text" className="form-control" id="address" required />
                <div className="invalid-feedback">
                  Please enter your shipping address.
                </div>
              </div>

              <div className="col-12">
                <label htmlFor="address" className="form-label">Url File</label>
                <input value={publication.url_file} onChange={(e) => setPublication({ ...publication, url_file: e.target.value })} type="text" className="form-control" id="address" required />
                <div className="invalid-feedback">
                  Please enter your shipping address.
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="col-md-4">
        <div className='shadow-sm'>
          <div className='card-body p-3'>
            <div className="row g-3">

              <div className="col-12">
                <div class="dropdown">
                  <div class="d-grid gap-2">
                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                      Topics
                    </button>
                    <ul class="dropdown-menu">
                      {topics?.map((topic, key) => (
                        <li key={key} className='dropdown-item'>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value={topic.id} id="flexCheckDefault"
                              checked={publication?.topics?.find((value, index) => value === topic.id)}
                              onChange={(e) => setPublication({
                                ...publication,
                                topics: e.target.checked ? [...publication.topics, Number(e.target.value)]
                                  : [...publication.topics.filter((f) => f !== Number(e.target.value))]
                              })}
                            />
                            <label class="form-check-label" for="flexCheckDefault">
                              {topic.name}
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div class="d-grid">
                <button className='btn btn-primary' onClick={() => onHandlerEdit()}>Simpan</button>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default PublicationEdit
/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../../utils/api';

const PublicationDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const path = location.pathname.split('/');
  const id = path[3];

  const [pub, setPub] = useState([]);

  useEffect(() => {
    const getPublicationsById = async () => {
      const publications = await api.getPublicationsById({ id });
      setPub(publications[0]);
    }

    getPublicationsById();
  }, [id]);

  const onDeleteHandler = async () => {
    const result = confirm('Yakin ingin menghapus?');
    if (result) {
      const message = await api.deletePublications({ id });
      alert(message);
      navigate('/app/publication')
    }
  }

  const onUpdateHandler = () => {
    navigate(`/app/publication/${id}/edit`)
  }

  const onUpdateIsDraftHandler = async () => {
    const message = await api.updatePublicationsIsDraft({ id });
    if (message) {
      setPub({ ...pub, isDraft: pub.isDraft === 0 ? 1 : 0 })
    }
    alert(message);
  }

  return (
    <div className='row'>
      <div className="col-12 col-md-8 mb-sm-3">

        <div className="row row-cols-1 g-3">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h1 className='h2'>{pub.title}</h1>
                <h2 className='h5'>{pub.author}</h2>
                <h2 className='h6'>{pub.provinsi}</h2>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card">
              <div className="card-body">
                <h1 className='h5'>Topic Area:</h1>
                {pub?.topic?.split(",").map((e) => (
                  <li>{e}</li>
                ))}
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card">
              <div className="card-body">
                <a href={pub.url_file} className='btn btn-primary'>PDF</a>
                <h2 className='h5'>Submitted</h2>
                <p className='h6'>{pub.submitted}</p>
                <h2 className='h5'>Published</h2>
                <p className='h6'>{pub.published}</p>
                <h2 className='h5'>Abstract</h2>
                <p>{pub.abstract}</p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card">
              <div className="card-body">
                <h1 className='h5'>Catatan:</h1>
                <div className="row g-3">

                  {!pub?.reviews?.length > 0 && <p>Belum di review!</p>}

                  {pub?.reviews?.map((review, key) => (
                    <div key={key} className="col-12">
                      <textarea value={review.message} rows={3} type="text" className="form-control" id="address" disabled />
                      <div class="form-text">{review.hasUpdated === 1 && 'Edited'} {review.updated_at}</div>
                    </div>
                  ))}

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="col-md-4">

        <div className="row row-cols-1 g-3">

          <div className="col">
            <div className="card">
              <div className="card-body">
                <div class="d-flex gap-2 justify-content-between">
                  <button onClick={() => onUpdateHandler()} class="btn btn-primary" type="button">Ubah</button>
                  <button onClick={() => onDeleteHandler()} class="btn btn-danger" type="button">Hapus</button>
                </div>
                <div className="d-grid gap-2 mt-3">
                  <button onClick={() => onUpdateIsDraftHandler()} className={`btn btn-${pub.isDraft === 1 ? 'primary' : 'warning'}`} type='button'>
                    {pub.isDraft === 1 ? 'Submit' : 'Draft'}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h1 className='h5'>Status:</h1>
                  <p>
                    <span class={`badge rounded-pill text-bg-${pub.publication_status === 'Published' ? 'success' : 'warning'}`}>{pub.publication_status}</span>
                  </p>
                </div>

                <div class="list-group">

                  {pub?.statusHistory?.map((e, key) => (
                    <label key={key} class={`list-group-item d-flex gap-3 ${e.isChecked && 'text-muted text-decoration-line-through'}`}>
                      <input disabled checked={e.isChecked} class="form-check-input flex-shrink-0" type="checkbox" style={{ fontSize: '1.375em' }} />
                      <span class="pt-1 form-checked-content">
                        <strong>{e.publication_status}</strong>
                        <small class="d-block text-body-secondary">
                          <i class="bi bi-calendar-event"></i>
                          {e.updated_at}
                        </small>
                      </span>
                    </label>
                  ))}

                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  )
}

export default PublicationDetail

import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import api from '../../utils/api';

const PublicationList = () => {
  const [searchBar, setSearchBar] = useState('');
  const [publications, setPublications] = useState([]);
  const [institution, setInstitution] = useState([]);

  useEffect(() => {
    const getPublications = async () => {
      const publications = await api.searchPublications({ q: searchBar, pageSize: 10, page: 1 });
      setPublications(publications);
    }

    getPublications();
  }, [searchBar]);

  useEffect(() => {
    const getListProvinceInstitution = async () => {
      const data = await api.getListProvinceInstitution();
      setInstitution(data);
    }

    getListProvinceInstitution();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-4">
          <div className="card shadow-sm mb-3">
            <div className="card-body">

              <select class="form-select" aria-label="Default select example">
                <option selected>Pilih Provinsi</option>
                {institution.map((e, key) => (
                  <option value="1">{e.province} - {e.name}</option>
                ))}
              </select>

              {/* <div class="list-group">
                <label class="list-group-item d-flex gap-2">
                  <input class="form-check-input flex-shrink-0" type="checkbox" value="" />
                  <span>
                    First checkbox
                    <small class="d-block text-body-secondary">With support text underneath to add more detail</small>
                  </span>
                </label>
                <label class="list-group-item d-flex gap-2">
                  <input class="form-check-input flex-shrink-0" type="checkbox" value="" />
                  <span>
                    Second checkbox
                    <small class="d-block text-body-secondary">Some other text goes here</small>
                  </span>
                </label>
                <label class="list-group-item d-flex gap-2">
                  <input class="form-check-input flex-shrink-0" type="checkbox" value="" />
                  <span>
                    Third checkbox
                    <small class="d-block text-body-secondary">And we end with another snippet of text</small>
                  </span>
                </label>
              </div> */}

            </div>
          </div>

        </div>

        <div className="col-12 col-md-8">
          <div className="card mb-3 shadow-sm">
            <div className="card-body">
              <form class="w-100 me-3" role="search">
                <input value={searchBar} onChange={(e) => setSearchBar(e.target.value)} type="search" class="form-control" placeholder="Search..." aria-label="Search" />
              </form>
            </div>
          </div>
          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">

            {publications?.data?.map((publication, key) => (
              <div key={key} class="col-md-6 col-lg-4">
                <Link to={`/publication/${publication.slug}`} class='text-decoration-none text-dark'>
                  <div class="card shadow-sm">
                    <img src={publication.urlImage ?? "https://picsum.photos/200/300"} alt={publication.title} className='card-img-top' width="100%" height="225" style={{ objectFit: 'cover' }} />
                    <div class="card-body">
                      <p class="card-text">{publication.title}</p>
                      <div class="d-flex justify-content-between align-items-center">
                        <small class="text-body-secondary">{publication.publishedAt + key} mins</small>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}

          </div>

        </div>
      </div >
    </>
  )
}

export default PublicationList
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from '../../../utils/api';

const MentorsList = () => {
  const navigate = useNavigate();
  const [mentors, setMentors] = useState([]);

  useEffect(() => {
    const getMentors = async () => {
      const data = await api.adminGetMentors();
      setMentors(data);
    }

    getMentors();
  }, []);

  return (
    <div className='card'>
      <div className="card-body table-responsive">
        <div className="d-flex justify-content-between mb-3">
          <h1 className='h5'>Mentor</h1>
          <div className="col-2">
            <div className="d-grid gap-2">
              <Link to='/admin/mentors/add' className='btn btn-primary'>Tambah</Link>
            </div>
          </div>
        </div>
        <table className='table table-hover'>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Nama</th>
              <th scope="col">Jabatan</th>
              <th scope="col">Keahlian</th>
            </tr>
          </thead>
          <tbody>
            {mentors?.map((mentor, key) => (
              <tr key={key} onClick={() => navigate(`/admin/mentors/${mentor.id}`)} style={{ cursor: 'pointer' }}>
                <th scope="row">{key + 1}</th>
                <td>{mentor.nama}</td>
                <td>{mentor.jabatan}</td>
                <td>{mentor.keahlian}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default MentorsList
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../../utils/api';

const Detail = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const path = location.pathname.split('/');
  const id = path[3];

  const [data, setData] = useState({});
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const getEvaluationsPATById = async () => {
      const data = await api.adminGetHasEvaluatedPATById({ id });
      setData(data);
      setRefresh(false);
    }

    getEvaluationsPATById();
  }, [id, refresh]);

  const onHandleDeleteEvaluated = async ({ fullname, finalEvaluateId, friendsId }) => {
    const confirm = window.confirm(`Yakin ingin menghapus ${fullname}?`);
    if (confirm) {
      await api.adminDeleteHasEvaluatedPATById({ id, finalEvaluateId, friendsId });
      setRefresh(true);
    }
  }

  return (
    <div className="row g-3">
      <div className='shadow-sm'>
        <div className='card-body m-3'>
          <div className="d-flex justify-content-between mb-3">
            <h1 className="h5">Penilaian Antar Teman</h1>
            <div className="col-2">
              {/* <div className="d-grid gap-2">
                <button onClick={() => onHandleDeleteMentors()} className='btn btn-danger'>Hapus</button>
              </div> */}
            </div>
          </div>
          <div className="row g-3">

            <div className="col-12">
              <div class="progress" role="progressbar" aria-label="Example with label" aria-valuenow={data?.infoEvaluated?.progress} aria-valuemin="0" aria-valuemax="100">
                <div class="progress-bar" style={{ width: data?.infoEvaluated?.progress + '%' }}>{data?.infoEvaluated?.progress}%</div>
              </div>

            </div>

            <div className="col-6">
              <label htmlFor="Nama" className="form-label">Sudah dinilai</label>
              <div className="input-group has-validation">
                <input disabled value={data?.infoEvaluated?.hasEvaluated} type="text" className="form-control" id="Nama" placeholder="Nama" required />
              </div>
            </div>

            <div className="col-6">
              <label htmlFor="Nama" className="form-label">Belum dinilai</label>
              <div className="input-group has-validation">
                <input disabled value={data?.infoEvaluated?.notEvaluated} type="text" className="form-control" id="Nama" placeholder="Nama" required />
              </div>
            </div>

            <hr />

            {data?.hasEvaluatedFriends?.map((friend, key) => (
              <div key={key} className="col-12">
                <div key={key} className="row g-3">

                  <div className="d-flex justify-content-end">
                    <div className="col-2">
                      <div className="d-grid gap-2">
                        <button onClick={() => onHandleDeleteEvaluated({ fullname: friend.fullname, finalEvaluateId: friend.id, friendsId: friend.friendsId })} className='btn btn-danger'>Hapus</button>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <label htmlFor="Nama" className="form-label">Nama</label>
                    <div className="input-group has-validation">
                      <input disabled value={friend?.fullname} type="text" className="form-control" id="Nama" placeholder="Nama" />
                    </div>
                  </div>

                  <div className="col-12">
                    <label htmlFor="Nilai Total" className="form-label">Nilai Total</label>
                    <div className="input-group has-validation">
                      <input disabled value={friend?.nilai_total} type="text" className="form-control" id="Nilai Total" placeholder="Nilai Total" />
                    </div>
                  </div>

                  <div className="col-12">
                    <label htmlFor="Rata Rata Nilai" className="form-label">Rata Rata Nilai</label>
                    <div className="input-group has-validation">
                      <input disabled value={friend?.rata_rata_nilai} type="text" className="form-control" id="Rata Rata Nilai" placeholder="Rata Rata Nilai" />
                    </div>
                  </div>

                  <div className="col-12">
                    <label htmlFor="Catatan" className="form-label">Catatan</label>
                    <div className="input-group has-validation">
                      <input disabled value={friend?.catatan} type="text" className="form-control" id="Catatan" placeholder="Catatan" />
                    </div>
                  </div>

                  <div className="col-12 table-responsive">
                    <table className='table table-hover'>
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Klasifikasi</th>
                          <th scope="col">Nilai</th>
                        </tr>
                      </thead>
                      <tbody>
                        {friend?.result?.map((value, key) => (
                          <tr key={key} onClick={() => navigate(`/admin/users/${value.id}`)} style={{ cursor: 'pointer' }}>
                            <th scope="row">{key + 1}</th>
                            <td>{value.klasifikasi}</td>
                            <td>{value.nilai}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

      </div>
    </div>
  )
}

export default Detail;

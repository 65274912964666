import React, { useEffect, useState } from 'react';
import api from '../../../../utils/api';
import { useNavigate } from 'react-router-dom';

const UpdateDiri = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState();
  const [provinces, setProvinces] = useState([]);
  const [kabKota, setKabKota] = useState([]);
  const [kabKotaNow, setKabKotaNow] = useState([]);
  const [kabKotaUniv, setKabKotaUniv] = useState([]);

  useEffect(() => {
    const onHandlerGetProfileByNim = async () => {
      const data = await api.getOwnFullProfile();
      setProfile(data);
      setKabKota([{ name: data?.kota_domisili_ktp }])
      setKabKotaNow([{ name: data?.kota_domisili_now }])
      setKabKotaUniv([{ name: data?.kota_domisili_universitas }])
    }

    const getProvinces = async () => {
      const provinces = await api.getProvinces();
      setProvinces(provinces)
    }

    onHandlerGetProfileByNim();
    getProvinces();
    console.log(kabKota);
  }, []);

  const onHandleGetKabKotaByIdPronvice = async ({ e }) => {
    setProfile({ ...profile, provinsi_domisili_ktp: e.target.value })
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index]
    const id = el.getAttribute('id');

    const kabKota = await api.getRegenciesByProvinceId(id);
    setKabKota(kabKota);
  }

  const onHandleGetKabKotaByIdPronviceNow = async ({ e }) => {
    setProfile({ ...profile, provinsi_domisili_now: e.target.value })
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index]
    const id = el.getAttribute('id');

    const kabKotaNow = await api.getRegenciesByProvinceId(id);
    setKabKotaNow(kabKotaNow);
  }

  const onHandleGetKabKotaByIdPronviceUniv = async ({ e }) => {
    setProfile({ ...profile, provinsi_domisili_universitas: e.target.value })
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index]
    const id = el.getAttribute('id');

    const kabKotaUniv = await api.getRegenciesByProvinceId(id);
    setKabKotaUniv(kabKotaUniv);
  }

  const onHandleSubmit = () => {
    const {
      tgl_lahir,
      nomor_ktp,
      provinsi_domisili_ktp,
      kota_domisili_ktp,
      provinsi_domisili_now,
      kota_domisili_now,
      alamat_lengkap_ktp,
      alamat_lengkap_now,
      nomor_rekening,
      nama_rekening,
      nama_bank,
      nomor_hp_darurat,
      hubungan_nomor_hp_darurat,
      provinsi_domisili_universitas,
      kota_domisili_universitas,
      program_studi,
      tahun_angkatan_kuliah,
    } = profile;

    if (tgl_lahir === "") return alert('Tgl Lahir Kosong!')
    if (nomor_ktp === "") return alert('Nomor KTP Kosong!')
    if (provinsi_domisili_ktp === "") return alert('Provinsi Domisili KTP Kosong!')
    if (kota_domisili_ktp === "") return alert('Kota Domisili KTP Kosong!')
    if (provinsi_domisili_now === "") return alert('Provinsi Domisili Sekarang Kosong!')
    if (kota_domisili_now === "") return alert('Kota Domisili Sekarang Kosong!')
    if (alamat_lengkap_ktp === "") return alert('Alamat Lengkap Berdasarkan KTP Kosong!')
    if (alamat_lengkap_now === "") return alert('Alamat Lengkap (Tempat Tinggal Saat Ini) Kosong!')
    if (nomor_rekening === "") return alert('Nomor Rekening Kosong!')
    if (nama_rekening === "") return alert('Nama Rekening Kosong!')
    if (nama_bank === "") return alert('Nama Bank Kosong!')
    if (nomor_hp_darurat === "") return alert('Nomor HP Darurat Kosong!')
    if (hubungan_nomor_hp_darurat === "") return alert('Hubungan Nomor HP Darurat Kosong!')
    if (provinsi_domisili_universitas === "") return alert('Provinsi Domisili Universitas Kosong!')
    if (kota_domisili_universitas === "") return alert('Kota Domisili Universitas Kosong!')
    if (program_studi === "") return alert('Program Studi Kosong!')
    if (tahun_angkatan_kuliah === "") return alert('Tahun Angkatan Kuliah Kosong!')

    const assign = {
      ...profile,
      program: 'Internship',
      cluster: 'Eliminasi TBC'
    }
    navigate('/app/profile/update/mobilisasi', { state: { user: assign } })
  }

  return (
    <>
      <div className="py-5 text-center">
        <h2>I. DATA DIRI</h2>
        <p className="lead">Isi bagian identitas mahasiswa dengan benar tanpa singkatan
          Perhatikan ketentuan dan syarat dari setiap pertanyaan yang diajukan</p>
      </div>

      <div className="row g-5">

        <div className="col-md-12 col-lg-8">
          <form className="needs-validation mb-5" noValidate>
            <div className="row g-3">

              <div className='shadow-sm'>
                <div className='card-body m-3'>
                  <h4 className="mb-3">Data Personal</h4>

                  <div className="row g-3">
                    <div className="col-12">
                      <label htmlFor="username" className="form-label">NIM</label>
                      <div className="input-group has-validation">
                        <input disabled value={profile?.nim} type="text" className="form-control" id="username" placeholder="Username" required />
                      </div>
                    </div>

                    <div className="col-12">
                      <label htmlFor="username" className="form-label">Nama</label>
                      <div className="input-group has-validation">
                        <input disabled value={profile?.nama} type="text" className="form-control" id="username" placeholder="Username" required="" />
                      </div>
                    </div>

                    <div className="col-12">
                      <label htmlFor="jenis_kelamin" className="form-label">Jenis Kelamin</label>
                      <select value={profile?.jenis_kelamin} onChange={(e) => setProfile({ ...profile, jenis_kelamin: e.target.value })} className="form-select" id="jenis_kelamin" required>
                        <option defaultValue>Pilih Jenis Kelamin</option>
                        <option value="L">Laki Laki</option>
                        <option value="P">Perempuan</option>
                      </select>
                    </div>

                    <div className="col-12">
                      <label htmlFor="email" className="form-label">Email <span className="text-body-secondary">(Optional)</span></label>
                      <input disabled value={profile?.email} type="email" className="form-control" id="email" placeholder="you@example.com" />
                    </div>

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">No HP</label>
                      <input disabled value={profile?.nomor_hp} type="text" className="form-control" id="address" required="" />
                    </div>

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Tanggal Lahir</label>
                      <input onChange={(e) => setProfile({ ...profile, tgl_lahir: e.target.value })} value={profile?.tgl_lahir} type="date" className="form-control" id="address" required="" />
                    </div>

                    <div className="col-12">
                      <label htmlFor="username" className="form-label">Nomor KTP</label>
                      <div className="input-group has-validation">
                        <input onChange={(e) => setProfile({ ...profile, nomor_ktp: e.target.value })} value={profile?.nomor_ktp} type="text" className="form-control" id="username" required="" />
                      </div>
                    </div>

                    <div className="col-6">
                      <label htmlFor="country" className="form-label">Provinsi Domisili KTP</label>
                      <select value={profile?.provinsi_domisili_ktp} onChange={(e) => onHandleGetKabKotaByIdPronvice({ e })} className="form-select" required="">
                        <option defaultValue>Pilih Provinsi</option>
                        {provinces.map((province, key) => (
                          <option key={key} id={province.id} value={province.name}>{province.name}</option>
                        ))}
                      </select>
                    </div>

                    <div className="col-6">
                      <label htmlFor="country" className="form-label">Kota Domisili KTP</label>
                      <select disabled={kabKota.length <= 1} value={profile?.kota_domisili_ktp} onChange={(e) => setProfile({ ...profile, kota_domisili_ktp: e.target.value })} className="form-select" required="" >
                        <option defaultValue>Pilih Kota</option>
                        {kabKota.map((kota, key) => (
                          <option key={key} value={kota.name}>{kota.name}</option>
                        ))}
                      </select>
                    </div>

                    <div className="col-6">
                      <label htmlFor="country" className="form-label">Provinsi Domisili Sekarang</label>
                      <select value={profile?.provinsi_domisili_now} onChange={(e) => onHandleGetKabKotaByIdPronviceNow({ e })} className="form-select" required="">
                        <option defaultValue>Pilih Provinsi</option>
                        {provinces.map((province, key) => (
                          <option key={key} id={province.id} value={province.name}>{province.name}</option>
                        ))}
                      </select>
                    </div>

                    <div className="col-6">
                      <label htmlFor="country" className="form-label">Kota Domisili Sekarang</label>
                      <select disabled={kabKotaNow.length <= 1} value={profile?.kota_domisili_now} onChange={(e) => setProfile({ ...profile, kota_domisili_now: e.target.value })} className="form-select" required="" >
                        <option defaultValue>Pilih Kota</option>
                        {kabKotaNow.map((kota, key) => (
                          <option key={key} value={kota.name}>{kota.name}</option>
                        ))}
                      </select>
                    </div>

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Alamat Lengkap Mahasiswa (Berdasarkan KTP)</label>
                      <input onChange={(e) => setProfile({ ...profile, alamat_lengkap_ktp: e.target.value })} value={profile?.alamat_lengkap_ktp} type="text" className="form-control" id="address" required="" />
                    </div>

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Alamat Lengkap Domisili Mahasiswa (Tempat Tinggal Saat Ini)</label>
                      <input onChange={(e) => setProfile({ ...profile, alamat_lengkap_now: e.target.value })} value={profile?.alamat_lengkap_now} type="text" className="form-control" id="address" required="" />
                    </div>
                  </div>

                </div>
              </div>

              <div className='shadow-sm'>
                <div className='card-body m-3'>
                  <h4 className="mb-3">Rekening</h4>

                  <div className="row g-3">
                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Nomor Rekening Mahasiswa</label>
                      <input onChange={(e) => setProfile({ ...profile, nomor_rekening: e.target.value })} value={profile?.nomor_rekening} type="text" className="form-control" id="address" required="" />
                    </div>

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Nama Lengkap Pemilik Rekening (Nama Sesuai Buku Rekening)</label>
                      <input onChange={(e) => setProfile({ ...profile, nama_rekening: e.target.value })} value={profile?.nama_rekening} type="text" className="form-control" id="address" required="" />
                    </div>

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Nama Bank</label>
                      <input onChange={(e) => setProfile({ ...profile, nama_bank: e.target.value })} value={profile?.nama_bank} type="text" className="form-control" id="address" required="" />
                    </div>
                  </div>

                </div>
              </div>

              <div className='shadow-sm'>
                <div className='card-body m-3'>
                  <h4 className="mb-3">Kontak Darurat</h4>

                  <div className="row g-3">
                    <div className="col-6">
                      <label htmlFor="address" className="form-label">Nomor HP Darurat</label>
                      <input onChange={(e) => setProfile({ ...profile, nomor_hp_darurat: e.target.value })} value={profile?.nomor_hp_darurat} type="text" className="form-control" id="address" required="" />
                    </div>

                    <div className="col-6">
                      <label htmlFor="country" className="form-label">Hubungan Nomor HP Darurat</label>
                      <select value={profile?.hubungan_nomor_hp_darurat} onChange={(e) => setProfile({ ...profile, hubungan_nomor_hp_darurat: e.target.value })} className="form-select" id="country" required="">
                        <option defaultValue>Pilih Hubungan Nomor HP Darurat</option>
                        <option value="Ayah">Ayah</option>
                        <option value="Ibu">Ibu</option>
                        <option value="Kakak">Kakak</option>
                        <option value="Adik">Adik</option>
                        <option value="Kakek">Kakek</option>
                        <option value="Nenek">Nenek</option>
                        <option value="Paman">Paman</option>
                        <option value="Tante/Bibi">Tante/Bibi</option>
                      </select>
                    </div>
                  </div>

                </div>
              </div>


              <div className='shadow-sm'>
                <div className='card-body m-3'>
                  <h4 className="mb-3">Universitas</h4>

                  <div className="row g-3">
                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Asal Universitas</label>
                      <input disabled value={profile?.asal_universitas} type="text" className="form-control" id="address" required="" />
                    </div>

                    {profile?.provinsi_domisili_universitas && profile?.kota_domisili_universitas === "" ?
                      <>
                        <div className="col-6">
                          <label htmlFor="country" className="form-label">Provinsi Domisili Universitas</label>
                          <select value={profile?.provinsi_domisili_universitas} onChange={(e) => onHandleGetKabKotaByIdPronviceUniv({ e })} className="form-select" required="">
                            <option defaultValue>Pilih Provinsi Domisili Universitas</option>
                            {provinces.map((province, key) => (
                              <option key={key} id={province.id} value={province.name}>{province.name}</option>
                            ))}
                          </select>
                        </div>

                        <div className="col-6">
                          <label htmlFor="country" className="form-label">Kota Domisili Universitas</label>
                          <select value={profile?.kota_domisili_universitas} onChange={(e) => setProfile({ ...profile, kota_domisili_universitas: e.target.value })} className="form-select" required="" >
                            <option defaultValue>Pilih Kota Domisili Universitas</option>
                            {kabKotaUniv.map((kota, key) => (
                              <option key={key} value={kota.name}>{kota.name}</option>
                            ))}
                          </select>
                        </div>
                      </>
                      :
                      <>
                        <div className="col-6">
                          <label htmlFor="address" className="form-label">Provinsi Domisili Universitas</label>
                          <input disabled value={profile?.provinsi_domisili_universitas} type="text" className="form-control" id="address" required="" />
                        </div>

                        <div className="col-6">
                          <label htmlFor="address" className="form-label">Kota Domisili Universitas</label>
                          <input disabled value={profile?.kota_domisili_universitas} type="text" className="form-control" id="address" required="" />
                        </div>
                      </>
                    }

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Program Studi</label>
                      <input disabled value={profile?.program_studi} type="text" className="form-control" id="address" required="" />
                    </div>

                    <div className="col-6">
                      <label htmlFor="jenjang_pendidikan" className="form-label">Jenjang Pendidikan</label>
                      <select value={profile?.jenjang_pendidikan} onChange={(e) => setProfile({ ...profile, jenjang_pendidikan: e.target.value })} className="form-select" id="jenjang_pendidikan" required>
                        <option defaultValue>Pilih Jenjang Pendidikan</option>
                        <option value="D3">D3</option>
                        <option value="D4">D4</option>
                        <option value="S1">S1</option>
                      </select>
                    </div>

                    <div className="col-6">
                      <label htmlFor="country" className="form-label">Tahun Angkatan Kuliah</label>
                      <select value={profile?.tahun_angkatan_kuliah} onChange={(e) => setProfile({ ...profile, tahun_angkatan_kuliah: e.target.value })} className="form-select" id="country" required="">
                        <option defaultValue>Pilih Tahun Angkatan Kuliah</option>
                        <option value="2018">2018</option>
                        <option value="2019">2019</option>
                        <option value="2020">2020</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                      </select>
                    </div>

                  </div>

                </div>
              </div>

              <div className='shadow-sm'>
                <div className='card-body m-3'>
                  <h4 className="mb-3">Program</h4>

                  <div className="row g-3">
                    <div className="col-6">
                      <label htmlFor="address" className="form-label">Program</label>
                      <input disabled value="Internship" type="text" className="form-control" id="address" required="" />
                    </div>

                    <div className="col-6">
                      <label htmlFor="address" className="form-label">Cluster</label>
                      <input disabled value="Eliminasi TBC" type="text" className="form-control" id="address" required="" />
                    </div>

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Penempatan</label>
                      <input disabled value={profile?.penempatan} type="text" className="form-control" id="address" required="" />
                    </div>

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Lembaga Mitra Penempatan Magang</label>
                      <input disabled value={profile?.lembaga_penempatan} type="text" className="form-control" id="address" required="" />
                    </div>

                    <div className="col-12">
                      <label htmlFor="address" className="form-label">Lembaga Divisi/Bidang</label>
                      <input disabled value={profile?.lembaga_divisi} type="text" className="form-control" id="address" required="" />
                    </div>
                  </div>

                </div>
              </div>

            </div>

            <button disabled={!profile} onClick={() => onHandleSubmit()} className="w-100 btn btn-primary btn-lg my-4" type="button">Lanjut</button>
          </form>
        </div>
      </div>
    </>
  )
}

export default UpdateDiri
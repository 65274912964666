import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import api from '../../utils/api';

const PublicationDetail = () => {
  const location = useLocation();
  const path = location.pathname.split('/');
  const slug = path[2];

  const [pub, setPub] = useState([]);

  useEffect(() => {
    const getPublicationsBySlug = async () => {
      const publications = await api.getPublicationsBySlug({ slug });
      setPub(publications[0]);
    }

    getPublicationsBySlug();
  }, [slug]);

  if (pub.isDraft === 1) return (
    <div class="shadow-sm text-center p-3">
      Publikasi tidak tersedia, hubungi author: {pub.author}
    </div >
  )

  return (
    <div className='row'>
      <div className="col-8">
        <div className="card">
          <img src={pub.url_image} alt={pub.title} style={{ objectFit: 'cover', height: '320px' }} />
          <div className="card-body">
            <h1 className='h2'>{pub.title}</h1>
            <h2 className='h5'>{pub.author}</h2>
            <h2 className='h6'>{pub.provinsi}</h2>
          </div>
        </div>
        <div className="card mt-3">
          <div className="card-body">
            <h2 className='h5'>Submitted</h2>
            <p className='h6'>{pub.submitted}</p>
            <h2 className='h5'>Published</h2>
            <p className='h6'>{pub.published}</p>
            <h2 className='h5'>Abstract</h2>
            <p>{pub.abstract}</p>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="row g-3 sticky-md-top" style={{ zIndex: 99 }}>
          <div className="card">
            <div className="card-body">
              <h1 className='h5'>Topic Area:</h1>
              {pub?.topic?.split(',').map((e) => (
                <span class="badge rounded-pill text-bg-light m-1">{e}</span>
              ))}
            </div>
          </div>

          <div className="d-grid gap-2">
            <a href={`${pub.url_file}`} target='_blank' rel='noreferrer' className='btn btn-primary'>PDF</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PublicationDetail
import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import { useNavigate } from 'react-router-dom';

const PublicationAdd = () => {
  const navigate = useNavigate();

  const [topics, setTopics] = useState([]);
  const [publication, setPublication] = useState({
    title: '',
    abstract: '',
    topics: [],
    url_image: 'https://source.unsplash.com/random',
    url_file: '',
    tgl_terbit: new Date().toISOString(),
  });

  useEffect(() => {
    const getTopics = async () => {
      const topics = await api.getTopics();
      setTopics(topics);
    }

    getTopics();
  }, []);

  const onHandlerAdd = async (e) => {
    const isDraft = Number(e.target.value);

    const payload = { ...publication, isDraft };
    console.log(payload);
    const message = await api.addPublications({ payload });
    alert(message);
    navigate('/app/publication');
  }

  return (
    <div className="row">

      <div className="col-md-8 col-lg-10 mb-sm-3">
        <div className='shadow-sm'>
          <div className='card-body p-3'>
            <div className="row g-3">

              <div className="col-12">
                <label htmlFor="address" className="form-label">Title</label>
                <input value={publication.title} onChange={(e) => setPublication({ ...publication, title: e.target.value })} type="text" className="form-control" id="address" required />
              </div>

              <div className="col-12">
                <label htmlFor="address" className="form-label">Abstract</label>
                <textarea value={publication.abstract} onChange={(e) => setPublication({ ...publication, abstract: e.target.value })} rows={4} type="text" className="form-control" id="address" required />
              </div>

              <div className="col-12">
                <label htmlFor="address" className="form-label">Image (URL)</label>
                <input value={publication.url_image} onChange={(e) => setPublication({ ...publication, url_image: e.target.value })} type="text" className="form-control" id="address" required />
              </div>

              <div className="col-12">
                <label htmlFor="address" className="form-label">File PDF(URL)</label>
                <input value={publication.url_file} onChange={(e) => setPublication({ ...publication, url_file: e.target.value })} type="text" className="form-control" id="address" required />
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="col-md-4 col-lg-2">
        <div className='shadow-sm'>
          <div className='card-body p-3'>
            <div className="row g-3">

              <div className="col-12">
                <div class="dropdown">
                  <div class="d-grid gap-2">
                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                      Topics
                    </button>
                    <ul class="dropdown-menu">
                      {topics?.map((topic, key) => (
                        <li key={key} className='dropdown-item'>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value={topic.id} id="flexCheckDefault"
                              onChange={(e) => setPublication({
                                ...publication,
                                topics: e.target.checked ? [...publication.topics, Number(e.target.value)]
                                  : [...publication.topics.filter((f) => f !== Number(e.target.value))]
                              })}
                            />
                            <label class="form-check-label" for="flexCheckDefault">
                              {topic.name}
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-between">
                <button value={1} className='btn btn-primary d-inline-flex align-items-center' onClick={(e) => onHandlerAdd(e)}>Draft</button>
                <button value={0} className='btn btn-primary d-inline-flex align-items-center' onClick={(e) => onHandlerAdd(e)}>Publish</button>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default PublicationAdd
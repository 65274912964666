import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import { evaluationsKpiSoftskill, evaluationsKpiHardskill } from '../../../utils/data';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const EvaluationAdd = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const path = location.pathname.split('/');

  const evaluateId = path[3];
  const divisiId = path[5];

  const [isLoading, setIsloading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [mentess, setMentess] = useState({});
  const [softskill, setSoftskill] = useState(evaluationsKpiSoftskill);
  const [hardskill, setHardskill] = useState(evaluationsKpiHardskill);

  useEffect(() => {
    const getClassficationsSoftskill = async () => {
      const data = await api.adminGetClassficationsSoftskill();
      const classfications = data.map((value) => ({ id: value.id, klasifikasi: value.klasifikasi, nilai: "", catatan: "" }))
      setSoftskill(classfications);
    }

    const getClassficationsHardskill = async () => {
      const data = await api.adminGetClassficationsHardskillByDivisiLembagaId({ id: divisiId });
      const classfications = data.map((value) => ({ id: value.id, klasifikasi: value.klasifikasi, nilai: "", catatan: "" }))
      setHardskill(classfications);
    }

    getClassficationsSoftskill();
    getClassficationsHardskill();
  }, []);

  useEffect(() => {
    const getEvaluationsById = async () => {
      const data = await api.adminGetEvaluatesById({ id: evaluateId });
      setMentess(data);
      console.log(data);
    }

    getEvaluationsById();
  }, [evaluateId]);

  const onHandleChangeNilaiSoftskill = ({ nilai, key }) => {
    if (nilai > 100) { alert('Nilai tidak boleh lebih dari 100!'); return }
    const newState = [...softskill];
    newState[key].nilai = nilai;
    setSoftskill(newState);
    if (newState[key].nilai < 1 || newState[4].nilai < 1) {
      setDisabled(true);
    } else {
      setDisabled(false)
    }
  }

  const onHandleChangeCatatanSoftskill = ({ catatan, key }) => {
    const newState = [...softskill];
    newState[key].catatan = catatan;
    setSoftskill(newState);
  }

  const onHandleChangeNilaiHardskill = ({ nilai, key }) => {
    if (nilai > 100) { alert('Nilai tidak boleh lebih dari 100!'); return }
    const newState = [...hardskill];
    newState[key].nilai = nilai;
    setHardskill(newState);
    if (newState[key].nilai < 1 || newState[key].nilai < 1) {
      setDisabled(true);
    } else {
      setDisabled(false)
    }
  }

  const onHandleChangeCatatanHardskill = ({ catatan, key }) => {
    const newState = [...hardskill];
    newState[key].catatan = catatan;
    setHardskill(newState);
  }

  const onHandlePostEvaluate = async () => {
    setIsloading(true);
    const newPayload = {
      softskill: softskill,
      hardskill: hardskill
    };
    console.log(newPayload);
    await api.mentorsAddEvaluate({ id: evaluateId, payload: newPayload });
    // await api.mentorsAddEvaluateSoftskill({ id: evaluateId, payload: softskillPayload });
    // await api.mentorsAddEvaluateHardskill({ id: evaluateId, payload: hardskillPayload });
    setTimeout(() => {
      alert('Berhasil dinilai!');
      navigate(`/mentors/evaluation/${evaluateId}`);
      setIsloading(false);
    }, 1000);
  }

  return (
    <div className='row g-4'>
      <div className="col-12 col-md-8">
        <div className="row g-3">

          <div className="shadow-sm">
            <div className="m-3">
              <h2 className="h5">Softskill</h2>
              {softskill?.map((value, key) => (
                <div className="col-12 mb-3">
                  <div className="row g-3">
                    <div className="col-8">
                      <label htmlFor="Klasifikasi" className="form-label">KPI {key + 1}:</label>
                      <input disabled value={value.klasifikasi} type="text" className="form-control" id="Klasifikasi" />
                    </div>

                    <div className="col-4">
                      <label htmlFor="Nilai" className="form-label">Nilai</label>
                      <input value={softskill[key].nilai} onChange={(e) => onHandleChangeNilaiSoftskill({ nilai: e.target.value, key })} type="number" placeholder='0' className="form-control" id="Nilai" min={1} max={100} required />
                    </div>

                    <div className="col-12">
                      <label htmlFor="Catatan" className="form-label">Catatan</label>
                      <textarea rows={2} value={softskill[key].catatan} onChange={(e) => onHandleChangeCatatanSoftskill({ catatan: e.target.value, key })} type="text" className="form-control" id="Catatan" required />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {hardskill?.length < 1 ?
            <p>Anda belum mengisi KPI <Link to={'/mentors/classifications'}>isi disini!</Link></p>
            :
            <>
              <div className="shadow-sm">
                <div className="m-3">
                  <h2 className="h5">Hardskill</h2>
                  {hardskill?.map((value, key) => (
                    <div className="col-12 mb-3">
                      <div className="row g-3">
                        <div className="col-8">
                          <label htmlFor="Klasifikasi" className="form-label">KPI {key + 1}:</label>
                          <textarea rows={3} disabled value={value.klasifikasi} type="text" className="form-control" id="Klasifikasi" />
                        </div>

                        <div className="col-4">
                          <label htmlFor="Nilai" className="form-label">Nilai</label>
                          <input value={hardskill[key].nilai} onChange={(e) => onHandleChangeNilaiHardskill({ nilai: e.target.value, key })} type="number" placeholder='0' className="form-control" id="Nilai" min={1} max={100} required />
                        </div>

                        <div className="col-12">
                          <label htmlFor="Catatan" className="form-label">Catatan</label>
                          <textarea rows={2} value={hardskill[key].catatan} onChange={(e) => onHandleChangeCatatanHardskill({ catatan: e.target.value, key })} type="text" className="form-control" id="Catatan" required />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="d-grid gap-2">
                <button disabled={disabled || isLoading} className='btn btn-primary' type='button' onClick={() => onHandlePostEvaluate()}>
                  {isLoading ?
                    <>
                      <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                      <span role="status"> Loading...</span>
                    </>
                    :
                    <>
                      <span>Simpan</span>
                    </>
                  }
                </button>
              </div>
            </>
          }

        </div>
      </div>

      <div className="col-12 col-md-4 order-md-last order-first">
        <div className="row g-3 sticky-md-top" style={{ zIndex: 99 }}>
          <div className="shadow-sm">
            <div className='card-body m-3'>
              <h1 className="h5">Profil</h1>

              <div className="row g-3">
                <div className="col-12">
                  <label htmlFor="mentess" className="form-label">Mentess</label>
                  <input disabled value={mentess?.mentess} type="text" className="form-control" id="mentess" placeholder="Mentess" required />
                </div>
                <div className="col-12">
                  <label htmlFor="divisi" className="form-label">Divisi</label>
                  <input disabled value={mentess?.divisi} type="text" className="form-control" id="divisi" placeholder="Divisi" required />
                </div>
                <div className="col-12">
                  <label htmlFor="jurusan" className="form-label">Divisi</label>
                  <input disabled value={mentess?.jurusan} type="text" className="form-control" id="jurusan" placeholder="Jurusan" required />
                </div>
                <div className="col-12">
                  <label htmlFor="jurusan" className="form-label">Universitas</label>
                  <input disabled value={mentess?.universitas} type="text" className="form-control" id="universitas" placeholder="Universitas" required />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default EvaluationAdd;

import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import { useLocation, useNavigate } from 'react-router-dom';

const MentorsDetail = () => {
  const [mentors, setMentors] = useState();

  const location = useLocation();
  const navigate = useNavigate();

  const path = location.pathname.split('/');
  const id = path[3];

  useEffect(() => {
    const getMentors = async () => {
      const data = await api.adminGetMentorsById({ id });
      setMentors(data);
    }

    getMentors();
  }, [id]);

  const onHandleDeleteMentors = async () => {
    const confirm = window.confirm('Yakin ingin menghapus mentor?');
    if (confirm) {
      await api.adminDeleteMentorsById({ id });
      navigate('/admin/mentors')
    }
  }

  return (
    <div className="row g-3">
      <div className='shadow-sm'>
        <div className='card-body m-3'>
          <div className="d-flex justify-content-between mb-3">
            <h1 className="h5">Data Mentor</h1>
            <div className="col-2">
              <div className="d-grid gap-2">
                <button onClick={() => onHandleDeleteMentors()} className='btn btn-danger'>Hapus</button>
              </div>
            </div>
          </div>

          <div className="row g-3">
            <div className="col-12">
              <label htmlFor="Nama" className="form-label">Nama</label>
              <div className="input-group has-validation">
                <input disabled value={mentors?.nama} type="text" className="form-control" id="Nama" placeholder="Nama" required />
              </div>
            </div>

            <div className="col-12">
              <label htmlFor="Nama" className="form-label">Email</label>
              <div className="input-group has-validation">
                <input disabled value={mentors?.email} type="text" className="form-control" id="Nama" placeholder="Nama" required />
              </div>
            </div>

            <div className="col-12">
              <label htmlFor="Jabatan" className="form-label">Jabatan</label>
              <div className="input-group has-validation">
                <input disabled value={mentors?.jabatan} type="text" className="form-control" id="Jabatan" placeholder="Jabatan" required />
              </div>
            </div>

            <div className="col-12">
              <label htmlFor="Keahlian" className="form-label">Keahlian</label>
              <div className="input-group has-validation">
                <input disabled value={mentors?.keahlian} type="text" className="form-control" id="Keahlian" placeholder="Keahlian" required />
              </div>
            </div>

            {mentors?.mentoring.map((mentor, key) => (
              <div key={key} className="row g-3">
                <div className="col-6">
                  <label htmlFor="Lembaga" className="form-label">Lembaga</label>
                  <div className="input-group has-validation">
                    <input disabled value={mentor?.lembaga} type="text" className="form-control" id="Lembaga" placeholder="Lembaga" required />
                  </div>
                </div>

                <div className="col-6">
                  <label htmlFor="Divisi" className="form-label">Divisi</label>
                  <div className="input-group has-validation">
                    <input disabled value={mentor?.divisi} type="text" className="form-control" id="Divisi" placeholder="Divisi" required />
                  </div>
                </div>

                <div className="col-12 table-responsive">
                  <table className='table table-hover'>
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">nama</th>
                        <th scope="col">Nomor HP</th>
                      </tr>
                    </thead>
                    <tbody>
                      {mentor?.mentess?.map((mentee, key) => (
                        <tr key={key} onClick={() => navigate(`/admin/users/${mentee.id}`)} style={{ cursor: 'pointer' }}>
                          <th scope="row">{key + 1}</th>
                          <td>{mentee.fullname}</td>
                          <td>{mentee.nomor_hp}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ))}

          </div>

        </div>
      </div>
    </div>
  )
}

export default MentorsDetail;

import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import { useNavigate, useSearchParams } from 'react-router-dom';

const UsersList = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [currentPage] = useState(searchParams.get("page") || "1");
  const [pageSize] = useState(searchParams.get("pageSize") || "25");

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (!searchParams.has('pageSize')) {
      setSearchParams({ ...searchParams, pageSize });
      navigate(`?page=${currentPage}&pageSize=${pageSize}`);
    }

    const getAllUsers = async () => {
      setLoading(true);
      const data = await api.adminGetAllUsers({ page: currentPage, pageSize });
      setUsers(data);
      setLoading(false);
    }

    getAllUsers();
  }, [pageSize, currentPage, searchParams, setSearchParams, navigate]);

  const handleNavigateToPage = (pageNumber) => {
    setSearchParams({ ...searchParams, page: pageNumber });
    navigate(`?page=${pageNumber}&pageSize=${pageSize}`);
  }

  const handlePageSize = (pageSize) => {
    setSearchParams({ ...searchParams, pageSize: pageSize });
    navigate(`?page=${currentPage}&pageSize=${pageSize}`)
  }

  return (
    <div className="card">
      <div className="card-body table-responsive">
        <div className="d-flex justify-content-between mb-3">
          <h1 className='h5'>Mahasiswa</h1>
          <div className="col-2">
            <select value={pageSize} onChange={(e) => handlePageSize(e.target.value)} class="form-select" aria-label="Default select example">
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Nama</th>
              <th scope="col">Lembaga</th>
              <th scope="col">Penempatan</th>
              <th scope="col">Universitas</th>
              <th scope="col">Batch</th>
              <th scope="col">Kategori</th>
            </tr>
          </thead>
          <tbody>
            {loading ?
              <>
                {Array.apply(null, Array(Number(pageSize))).map(() => (
                  <tr className='placeholder-glow'>
                    <th><span className='placeholder col-12'></span></th>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                    <td><span className='placeholder col-12'></span></td>
                  </tr>
                ))}
              </>
              :
              <>
                {users?.data?.map((user, key) => (
                  <tr key={key} onClick={() => navigate(`/admin/users/${user.id}`)} style={{ cursor: 'pointer' }}>
                    <th scope="row">{(currentPage - 1) * pageSize + (key + 1)}</th>
                    <td>{user.nama}</td>
                    <td>{user.lembaga_penempatan}</td>
                    <td>{user.lembaga_location}</td>
                    <td>{user.asal_universitas}</td>
                    <td>{user.batch}</td>
                    <td>{user.category}</td>
                  </tr>
                ))}
              </>
            }
          </tbody>
        </table>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item">
              <button onClick={() => handleNavigateToPage(users.previous)} class={`page-link ${!users.previous && 'disabled'}`}>Previous</button>
            </li>
            <li class="page-item"><p class="page-link">{users.current + 1 || parseInt(currentPage)}</p></li>
            <li class="page-item">
              <button onClick={() => handleNavigateToPage(users.next + 1)} class={`page-link ${!users.next && 'disabled'}`}>Next</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default UsersList
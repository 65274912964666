import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../utils/api';

const EvaluationDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const getEvaluationsById = async () => {
      const data = await api.adminGetEvaluatesById({ id });
      setData(data);
      setRefresh(false);
    }

    getEvaluationsById();
  }, [id, refresh]);

  const onHandleResetEvaluatedFromMentors = async () => {
    const { mentess, mentorsId } = data;
    const confirm = window.confirm(`Apakah anda ingin mereset nilai dari ${mentess}?`);
    if (confirm) {
      const message = await api.adminDeleteHasEvaluatedFromMentors({ id, mentorsId });
      alert(message);
      setRefresh(true);
    }
  }

  return (
    <div className="row g-3">
      <div className='shadow-sm'>
        <div className='card-body m-3'>
          <div className="d-flex justify-content-between mb-3">
            <h1 className="h5">Penilaian</h1>
            <div className="col-2">
              <div className="d-grid gap-2">
                {/* <button onClick={() => onHandleDeleteMentors()} className='btn btn-danger'>Hapus</button> */}
              </div>
            </div>
          </div>

          <div className="row g-3">
            <div className="col-12">
              <label htmlFor="Mentors" className="form-label">Mentors</label>
              <div className="input-group has-validation">
                <input disabled value={data?.mentors} type="text" className="form-control" id="Mentors" placeholder="Mentors" />
                <button onClick={() => navigate(`/admin/mentors/${data.mentorsId}`)} className="btn btn-primary" type="button" id="button-addon2">Detil</button>
              </div>
            </div>

            <div className="col-12">
              <label htmlFor="Mentess" className="form-label">Mentess</label>
              <div className="input-group has-validation">
                <input disabled value={data?.mentess} type="text" className="form-control" id="Mentess" placeholder="Mentess" />
                <button onClick={() => navigate(`/admin/users/${data.usersId}`)} className="btn btn-primary" type="button" id="button-addon2">Detil</button>
              </div>
            </div>

            <div className="col-12">
              <label htmlFor="Jurusan" className="form-label">Jurusan</label>
              <div className="input-group has-validation">
                <input disabled value={data?.jurusan} type="text" className="form-control" id="Jurusan" placeholder="Jurusan" />
              </div>
            </div>

            <div className="col-12">
              <label htmlFor="Universitas" className="form-label">Universitas</label>
              <div className="input-group has-validation">
                <input disabled value={data?.universitas} type="text" className="form-control" id="Universitas" placeholder="Universitas" />
              </div>
            </div>

            <div className="col-12">
              <label htmlFor="Divisi" className="form-label">Divisi</label>
              <div className="input-group has-validation">
                <input disabled value={data?.divisi} type="text" className="form-control" id="Divisi" placeholder="Divisi" />
              </div>
            </div>

            <div className="col-12">
              <label htmlFor="Catatan" className="form-label">Catatan</label>
              <div className="input-group has-validation">
                <input disabled value={data?.catatan} type="text" className="form-control" id="Catatan" placeholder="Catatan" />
              </div>
            </div>

            <div className="col-12">
              <label htmlFor="Nilai Rata-Rata" className="form-label">Nilai Rata-Rata</label>
              <div className="input-group has-validation">
                <input disabled value={data?.average_nilai} type="text" className="form-control" id="Nilai Rata-Rata" placeholder="Nilai Rata-Rata" />
              </div>
            </div>

            <div className="col-12">
              <label htmlFor="Nilai Akhir" className="form-label">Nilai Akhir</label>
              <div className="input-group has-validation">
                <input disabled value={data?.total_nilai} type="text" className="form-control" id="Nilai Akhir" placeholder="Nilai Akhir" />
              </div>
            </div>

            <div className="d-flex justify-content-between">
              <h1 className="h5">Penilaian Antar Teman</h1>
              <div className="col-2">
                <div className="d-grid gap-2">
                  <button onClick={() => navigate(`/admin/evaluation/${id}/pat/evaluated`)} className='btn btn-primary'>Detil</button>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div class="progress" role="progressbar" aria-label="Example with label" aria-valuenow={data?.infoEvaluated?.progress} aria-valuemin="0" aria-valuemax="100">
                <div class="progress-bar" style={{ width: data?.infoEvaluated?.progress + '%' }}>{data?.infoEvaluated?.progress}%</div>
              </div>
            </div>

            <div className="col-6">
              <label htmlFor="Nama" className="form-label">Sudah dinilai</label>
              <div className="input-group has-validation">
                <input disabled value={data?.infoEvaluated?.hasEvaluated} type="text" className="form-control" id="Nama" placeholder="Nama" />
              </div>
            </div>

            <div className="col-6">
              <label htmlFor="Nama" className="form-label">Belum dinilai</label>
              <div className="input-group has-validation">
                <input disabled value={data?.infoEvaluated?.notEvaluated} type="text" className="form-control" id="Nama" placeholder="Nama" />
              </div>
            </div>

            <div className="col-12 table-responsive card p-3">

              <div className="d-flex justify-content-between mb-3">
                <h1 className='h5'>Bakrie Center Foundation</h1>
                <div className="col-2">
                  <div className="d-grid gap-2">
                    <button onClick={() => navigate(`/admin/evaluation/${id}/evaluate`)} className='btn btn-primary'>Nilai</button>
                  </div>
                </div>
              </div>
              {!data?.by_bcf?.length < 1 &&
                <table className='table table-hover'>
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">KPI</th>
                      <th scope="col">Nilai</th>
                      <th scope="col">Catatan</th>
                      <th scope="col">Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.by_bcf?.map((bcf, key) => (
                      <tr key={key} style={{ cursor: 'default' }}>
                        <th scope="row">{key + 1}</th>
                        <td>{bcf.klasifikasi}</td>
                        <td>{bcf.nilai}</td>
                        <td>{bcf.catatan}</td>
                        <td>{bcf.klasifikasi === 'Kerjasama & Keaktifan' && <button onClick={() => navigate(`/admin/evaluation/${id}/pat`)} className='btn btn-primary badge rounded-pill'>Lihat</button>}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              }

            </div>

            <div className="col-12 table-responsive card p-3">
              <div className="d-flex justify-content-between mb-3">
                <h1 className="h5">Mentors</h1>
                <div className="col-2">
                  <div className="d-grid gap-2">
                    <button onClick={() => onHandleResetEvaluatedFromMentors()} className='btn btn-danger'>Reset</button>
                  </div>
                </div>
              </div>
              <table className='table table-hover'>
                {!data?.hardskills_by_mentors?.length < 1 ?
                  <>
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">KPI Hardskill</th>
                        <th scope="col">Nilai</th>
                        <th scope="col">Catatan</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.hardskills_by_mentors?.map((hard, key) => (
                        <tr key={key} style={{ cursor: 'default' }}>
                          <th scope="row">{key + 1}</th>
                          <td>{hard.klasifikasi}</td>
                          <td>{hard.nilai}</td>
                          <td>{hard.catatan}</td>
                        </tr>
                      ))}
                    </tbody>
                  </>
                  :
                  <p>Mentor belum menilai!</p>
                }
                {!data?.softskills_by_mentors?.length < 1 &&
                  <>
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">KPI Softskill</th>
                        <th scope="col">Nilai</th>
                        <th scope="col">Catatan</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.softskills_by_mentors?.map((soft, key) => (
                        <tr key={key} style={{ cursor: 'default' }}>
                          <th scope="row">{key + 1}</th>
                          <td>{soft.klasifikasi}</td>
                          <td>{soft.nilai}</td>
                          <td>{soft.catatan}</td>
                        </tr>
                      ))}
                    </tbody>
                  </>
                }
              </table>
            </div>

            <div className="col-12 table-responsive card p-3">
              <h1 className='h5'>Total Nilai</h1>
              <table className='table table-hover'>
                {!data?.evaluate_for_msib?.length < 1 ?
                  <>
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Nilai MSIB</th>
                        <th scope="col">Catatan</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.evaluate_for_msib?.map((msib, key) => (
                        <tr key={key} style={{ cursor: 'default' }}>
                          <th scope="row">{key + 1}</th>
                          <td>{msib.nilai}</td>
                          <td>Hardskill + Softskill</td>
                        </tr>
                      ))}
                    </tbody>
                  </>
                  :
                  <p>Belum dinilai!</p>
                }
              </table>
            </div>

          </div>

        </div>
      </div>
    </div>
  )
}

export default EvaluationDetail;

const api = (() => {
  const {
    REACT_APP_PROTOCOL,
    REACT_APP_HOST,
    REACT_APP_PORT
  } = process.env;
  const BASE_URL = `${REACT_APP_PROTOCOL}://${REACT_APP_HOST}:${REACT_APP_PORT}`;

  function putAccessToken(token) {
    localStorage.setItem('accessToken', token);
  }

  function getAccessToken() {
    return localStorage.getItem('accessToken');
  }

  function putRefreshToken(token) {
    return localStorage.setItem('refreshToken', token);
  }

  function getRefreshToken() {
    return localStorage.getItem('refreshToken');
  }

  function deleteToken() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }

  async function _fetchWithAuth(url, options = {}) {
    return fetch(url, {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
  }

  async function register({ name, email, password }) {
    const response = await fetch(`${BASE_URL}/users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        email,
        password,
      }),
    });

    const responseJson = await response.json();
    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return { status };
  }

  async function login({ email, password }) {
    const response = await fetch(`${BASE_URL}/authentications`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });

    const responseJson = await response.json();
    const { status, message, data } = responseJson;

    // if (status !== 'success') {
    //   throw new Error(message);
    // }

    return { status, message, data };
    // return data;
  }

  async function loginAsEmpBcf({ email, password }) {
    const response = await fetch(`${BASE_URL}/authentications/bcf`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });

    const responseJson = await response.json();
    const { status, message, data } = responseJson;

    // if (status !== 'success') {
    //   throw new Error(message);
    // }

    return { status, message, data };
    // return data;
  }

  async function loginAsMentors({ email, password }) {
    const response = await fetch(`${BASE_URL}/authentications/clp/mentors`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });

    const responseJson = await response.json();
    const { status, message, data } = responseJson;

    return { status, message, data };
  }

  async function getOwnProfile() {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/users/me`);
    const responseJson = await response.json();

    const { status, message, error, data } = responseJson;

    return { status, error, message, data };
  }

  async function getOwnFullProfile({ batchId }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/users?batchId=${batchId}`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function getOwnOfferedProgram() {
    const response = await _fetchWithAuth(`${BASE_URL}/program/offered-program/users`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function refreshToken() {
    const response = await fetch(`${BASE_URL}/authentications`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        refreshToken: `${getRefreshToken()}`,
      }),
    });

    const responseJson = await response.json();
    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }
    putAccessToken(data.accessToken);
  }

  async function getCheckUsersByNim({ nim }) {
    const response = await fetch(`${BASE_URL}/clp/users/${nim}/update`);

    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      return { status, message, data: null }
    }

    return { status, message: null, data };
  }

  async function getProfileByNim({ nim }) {
    const response = await fetch(`${BASE_URL}/clp/users/${nim}/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      return { status, message, data: null }
    }

    return { status, message: null, data };
  }

  // CLP Updated Profile
  async function updateProfileByNim({ profile, nim }) {
    const response = await fetch(`${BASE_URL}/clp/users/${nim}/update`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...profile
      }),
    });

    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      return { status, message, data: null }
    }

    return { status, message: null, data };
  }

  // async function getProvinces() {
  //   const response = await fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/provinces.json`);

  //   const responseJson = await response.json();

  //   // const { status, message, data } = responseJson;

  //   // if (status !== 'success') {
  //   //   throw new Error(message);
  //   // }

  //   return responseJson;
  // }

  // async function getKabKotaByIdPronvice(id) {
  //   const response = await fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/regencies/${id}.json`);

  //   const responseJson = await response.json();

  //   // const { status, message, data } = responseJson;

  //   // if (status !== 'success') {
  //   //   throw new Error(message);
  //   // }

  //   return responseJson;
  // }

  // Pronvices & Domicilies
  async function getProvinces() {
    const response = await fetch(`${BASE_URL}/domicile/provinces`);

    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function getRegenciesByProvinceId(id) {
    const response = await fetch(`${BASE_URL}/domicile/${id}/regencie`);

    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  // Publication Feature

  async function searchPublications({ q, pageSize, page }) {
    const response = await fetch(`${BASE_URL}/clp/publications?q=${q}&pageSize=${pageSize}&page=${page}`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function getPublicationsBySlug({ slug }) {
    const response = await fetch(`${BASE_URL}/clp/publications/${slug}`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function getListProvinceInstitution() {
    const response = await fetch(`${BASE_URL}/clp/publications/institution/province`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  // App (LoggedIn)

  async function addPublications({ payload }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/publications`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const responseJson = await response.json();

    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return message;
  }

  async function deletePublications({ id }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/publications/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const responseJson = await response.json();

    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return message;
  }

  async function updatePublications({ id, payload }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/publications/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const responseJson = await response.json();

    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return message;
  }

  async function getPublicationsApp({ pageSize, page }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/app/publications?pageSize=${pageSize}&page=${page}`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function getPublicationsById({ id }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/app/publications/${id}`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function getPublicationsForUpdateById({ id }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/publications/${id}/update`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function getTopics() {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/topics`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function updatePublicationsIsDraft({ id }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/publications/${id}/draft`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const responseJson = await response.json();

    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return message;
  }

  // Evaluation (PAT)
  async function appGetFriendsDivisiPAT({ batchId }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/evaluations-pat/evaluated/users?batchId=${batchId}`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function appPostEvaluatePAT({ id, payload }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/evaluations-pat/evaluated/users/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const responseJson = await response.json();

    const { status, message } = responseJson;

    return { status, message };
  }

  async function appGetClassificationsPAT() {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/classifications-pat`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function appGetFinalEvaluations({ batchId }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/evaluations-final/users?batchId=${batchId}`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    return { status, message, data };
  }

  // Admin (LoggedIn)
  async function getDashboardAdmin() {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/dashboard`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function getPublicationsAdmin({ publicationStatus, pageSize, page }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/publications?publicationStatus=${publicationStatus}&pageSize=${pageSize}&page=${page}`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function getStatusPublicationsAdmin() {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/publications/status`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function adminGetPublicationsById({ id }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/publications/${id}`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function adminPostReviewsForPublications({ id, payload }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/publications/${id}/reviews`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const responseJson = await response.json();

    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return message;
  }

  async function adminPutReviewsForPublications({ publicationId, reviewId, payload }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/publications/${publicationId}/reviews/${reviewId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const responseJson = await response.json();

    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return message;
  }

  async function adminDeleteReviewsForPublications({ publicationId, reviewId }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/publications/${publicationId}/reviews/${reviewId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      }
    });
    const responseJson = await response.json();

    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return message;
  }

  async function adminPutStatusHistoryForPublications({ publicationId, publicationHistoryId }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/publications/${publicationId}/status/${publicationHistoryId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const responseJson = await response.json();

    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return message;
  }

  async function adminGetAllUsers({ page, pageSize }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/users?pageSize=${pageSize}&page=${page}`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function adminGetUsersById({ id }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/users/${id}`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function adminUsersExports({ kepulangan }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/exports/users?status=${kepulangan}`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function adminGetEvaluationsInstitutions() {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/evaluations/institutions`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function adminGetEvaluationsInstitutionsById({ id }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/evaluations/institutions/${id}`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function mentorsGetAllInstitutions() {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/mentors/institutions`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function mentorsGetInstitutionsById({ id }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/mentors/institutions/${id}`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function mentorsGetClassificationsHardskillByMentors() {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/mentors/classifications/hardskill`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function adminAddMentors({ payload }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/mentors`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const responseJson = await response.json();

    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return message;
  }

  async function adminDeleteMentorsById({ id }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/mentors/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const responseJson = await response.json();

    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return message;
  }

  async function adminGetMentors() {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/mentors`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function adminGetMentorsById({ id }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/mentors/${id}`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function adminAddClassifications({ payload }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/classifications/hardskill`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const responseJson = await response.json();

    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return message;
  }

  async function adminGetClassficationsHardskill() {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/classifications/hardskill`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function adminGetClassficationsHardskillByDivisiLembagaId({ id }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/classifications/hardskill/${id}/divisibylembaga`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function adminGetClassficationsBCF() {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/classifications/bcf`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function adminGetClassficationsBCFByUsersId({ id }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/classifications/${id}/bcf`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function adminGetClassficationsSoftskill() {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/classifications/softskill`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function adminGetAllEvaluates({ page, pageSize }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/evaluations?pageSize=${pageSize}&page=${page}`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function mentorsGetAllEvaluates({ page, pageSize }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/mentors/evaluations?pageSize=${pageSize}&page=${page}`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function adminGetEvaluatesById({ id }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/evaluations/${id}`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function adminGetEvaluatesPATById({ id }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/evaluations/${id}/pat`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function adminGetHasEvaluatedPATById({ id }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/evaluations/${id}/pat/evaluated`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function adminDeleteHasEvaluatedPATById({ id, finalEvaluateId, friendsId }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/evaluations/${id}/pat/evaluated/${finalEvaluateId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ friendsId }),
    });
    const responseJson = await response.json();

    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return message;
  }

  async function adminGetInstitutions() {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/institutions`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function adminGetInstitutionsById({ id }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/institutions/${id}`);
    const responseJson = await response.json();

    const { status, message, data } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return data;
  }

  async function adminDeleteHasEvaluatedFromMentors({ id, mentorsId }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/evaluations/${id}/evaluates/${mentorsId}/mentors`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const responseJson = await response.json();

    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return message;
  }

  // NOT USED!
  async function adminAddEvaluate({ id, payload }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/evaluations/${id}/evaluates`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const responseJson = await response.json();

    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return message;
  }

  async function adminUpdateEvaluate({ id, payload }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/admin/evaluations/${id}/evaluates`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const responseJson = await response.json();

    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return message;
  }

  async function mentorsAddEvaluateSoftskill({ id, payload }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/mentors/evaluations/${id}/evaluates/softskill`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const responseJson = await response.json();

    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return message;
  }

  async function mentorsAddEvaluateHardskill({ id, payload }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/mentors/evaluations/${id}/evaluates/hardskill`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const responseJson = await response.json();

    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return message;
  }

  async function mentorsAddEvaluate({ id, payload }) {
    const response = await _fetchWithAuth(`${BASE_URL}/clp/mentors/evaluations/${id}/evaluates`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
    const responseJson = await response.json();

    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    return message;
  }

  return {
    putAccessToken,
    getAccessToken,
    putRefreshToken,
    getRefreshToken,
    refreshToken,
    deleteToken,
    register,
    login,
    getOwnProfile,
    getOwnFullProfile,
    getOwnOfferedProgram,
    getCheckUsersByNim,
    getProfileByNim,
    updateProfileByNim,
    getProvinces,
    getRegenciesByProvinceId,
    getPublicationsApp,
    searchPublications,
    getPublicationsBySlug,
    getListProvinceInstitution,
    getPublicationsById,
    deletePublications,
    addPublications,
    updatePublications,
    getPublicationsForUpdateById,
    getTopics,
    updatePublicationsIsDraft,
    // Users (PAT)
    appGetFriendsDivisiPAT,
    appPostEvaluatePAT,
    appGetClassificationsPAT,
    // Evalautions
    appGetFinalEvaluations,
    // Admin
    getDashboardAdmin,
    getPublicationsAdmin,
    getStatusPublicationsAdmin,
    adminGetPublicationsById,
    adminPostReviewsForPublications,
    adminPutReviewsForPublications,
    adminDeleteReviewsForPublications,
    adminPutStatusHistoryForPublications,
    adminGetAllUsers,
    adminGetUsersById,
    adminUsersExports,
    loginAsEmpBcf,
    loginAsMentors,
    // Evaluate
    // Admin Mentors
    adminGetEvaluationsInstitutions,
    adminGetEvaluationsInstitutionsById,
    adminAddMentors,
    adminDeleteMentorsById,
    adminGetMentors,
    adminGetMentorsById,
    adminAddClassifications,
    adminGetClassficationsHardskill,
    adminGetClassficationsHardskillByDivisiLembagaId,
    adminGetClassficationsBCF,
    adminGetClassficationsBCFByUsersId,
    adminGetClassficationsSoftskill,
    adminGetAllEvaluates,
    mentorsGetAllEvaluates,
    adminGetInstitutions,
    adminGetInstitutionsById,
    adminGetEvaluatesById,
    adminGetEvaluatesPATById,
    adminGetHasEvaluatedPATById,
    adminDeleteHasEvaluatedPATById,
    adminAddEvaluate,
    adminUpdateEvaluate,
    adminDeleteHasEvaluatedFromMentors,
    mentorsAddEvaluateSoftskill,
    mentorsAddEvaluateHardskill,
    mentorsAddEvaluate,
    mentorsGetAllInstitutions,
    mentorsGetInstitutionsById,
    mentorsGetClassificationsHardskillByMentors,
  }
})();

export default api;

import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { loginAsMentorsAction } from '../../utils/action';
import ToastNotifications from '../../Components/ToastNotifications';

const Login = ({ setAuthUser }) => {
  const navigate = useNavigate();

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [messageNotifications, setMessage] = useState('');
  const [show, setShow] = useState(false);

  const onHandleLogin = async () => {
    const { status, message, data: authUser } = await loginAsMentorsAction({ email, password })
    if (status === 'success') {
      setAuthUser(authUser);
      navigate('/mentors/evaluation');
    } else {
      setMessage(message);
      setShow(true);
    }
  };

  return (
    <>
      <div class="w-100 m-auto" style={{ maxWidth: '330px', padding: '1rem' }}>
        <form className='text-center'>
          <img class="mb-4" style={{ width: 150, height: 150, objectFit: 'scale-down' }} src="/favicon.ico" alt="Bakrie Center Foundation" />
          <h1 class="h3 mb-3 fw-normal">Masuk Mentor</h1>

          <div class="form-floating mb-2">
            <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" class="form-control" id="floatingInput" placeholder="name@example.com" />
            <label for="floatingInput">Email</label>
          </div>
          <div class="form-floating mb-3">
            <input value={password} onChange={(e) => setPassword(e.target.value)} type="password" class="form-control" id="floatingPassword" placeholder="Password" />
            <label for="floatingPassword">Password</label>
          </div>

          <button onClick={() => onHandleLogin()} class="btn btn-primary w-100 py-2" type="button">masuk</button>
        </form>
      </div>
      <ToastNotifications setShowNotifications={setShow} show={show} message={messageNotifications} />
    </>
  )
}

export default Login
import React, { useState, useEffect } from 'react';
import api from '../../../utils/api';
import { useNavigate } from 'react-router-dom';

const MentorsAdd = () => {
  const navigate = useNavigate();

  const [isLoading, setIsloading] = useState(false);
  const [data, setData] = useState({
    nama: '',
    email: '',
    jabatan: '',
    keahlian: '',
    divisiLembagaId: [],
  });
  const [institutions, setInstitutions] = useState([]);
  // const [divisions, setDivisions] = useState([]);
  const [mentoring, setMentoring] = useState([
    {
      institutionId: 388,
      divisiId: 135,
      divisions: []
    },
  ]);

  useEffect(() => {
    const getInstitutions = async () => {
      const data = await api.adminGetEvaluationsInstitutions();
      setInstitutions(data);
    }

    getInstitutions();
  }, []);

  const onHandleDivisiByInstitutionId = async ({ id, key }) => {
    const data = await api.adminGetEvaluationsInstitutionsById({ id });
    const newState = [...mentoring];
    newState[key].institutionId = id;
    newState[key].divisions = data;
    setMentoring(newState);
  }

  const onHandleDivisiId = ({ id, key }) => {
    // setData({ ...data, divisiLembagaId: [...data.divisiLembagaId, id] })
    const newState = { ...data };
    newState.divisiLembagaId[key] = id;
    setData(newState);
  }

  const onHandlePostMentors = async () => {
    setIsloading(true);
    setTimeout(async () => {
      const message = await api.adminAddMentors({ payload: data });
      setIsloading(false);
      alert(message);
      setData({
        nama: '',
        email: '',
        jabatan: '',
        keahlian: '',
        divisiLembagaId: [],
      });
      setMentoring([
        {
          institutionId: 0,
          divisiId: 0,
          divisions: []
        },
      ]);
      navigate('/admin/mentors')
    }, 1000);
  }

  return (
    <div className='row'>
      <div className="col-12 col-md-6 mb-sm-3">
        <div className="shadow-sm">
          <div className="card-body p-3">
            <h1 className='h5'>Mentor</h1>
            <div className="row g-3">

              <div className="col-12">
                <label htmlFor="nama" className="form-label">Nama</label>
                <input value={data.nama} onChange={(e) => setData({ ...data, nama: e.target.value })} type="text" className="form-control" id="nama" required />
              </div>

              <div className="col-12">
                <label htmlFor="nama" className="form-label">Email</label>
                <input value={data.email} onChange={(e) => setData({ ...data, email: e.target.value })} type="text" className="form-control" id="nama" required />
              </div>

              <div className="col-12">
                <label htmlFor="jabatan" className="form-label">Jabatan</label>
                <input value={data.jabatan} onChange={(e) => setData({ ...data, jabatan: e.target.value })} type="text" className="form-control" id="jabatan" required />
              </div>

              <div className="col-12">
                <label htmlFor="keahlian" className="form-label">Keahlian</label>
                <input value={data.keahlian} onChange={(e) => setData({ ...data, keahlian: e.target.value })} type="text" className="form-control" id="keahlian" required />
              </div>

              <div className="col-12">
                <div className="d-grid gap-2">
                  <button disabled={mentoring.length < 1 || isLoading} onClick={() => onHandlePostMentors()} className='btn btn-primary'>
                    {isLoading ?
                      <>
                        <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
                        <span role="status"> Loading...</span>
                      </>
                      :
                      <>
                        <span>Simpan</span>
                      </>
                    }
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="col-md-6">
        <div className="shadow-sm">
          <div className="card-body p-3">
            <h1 className='h5'>Mentoring</h1>
            {mentoring?.map((e, key) => (
              <div className="row g-3">

                <div className="col-6">
                  <label htmlFor="country" className="form-label">Lembaga</label>
                  <select onChange={(e) => onHandleDivisiByInstitutionId({ id: e.target.value, key })} className="form-select" required>
                    <option defaultValue>Pilih Lembaga</option>
                    {institutions.map((institution, key) => (
                      <option key={key} value={institution.id}>{institution.name}</option>
                    ))}
                  </select>
                </div>

                <div className="col-6">
                  <label htmlFor="country" className="form-label">Divisi</label>
                  <select disabled={mentoring[key]?.divisions?.length <= 0} onChange={(e) => onHandleDivisiId({ id: e.target.value, key })} className="form-select" required>
                    <option defaultValue>Pilih Divisi</option>
                    {mentoring[key]?.divisions?.map((divisi, key) => (
                      <option key={key} value={divisi.id}>{divisi.divisi}</option>
                    ))}
                  </select>
                </div>

              </div>
            ))}

            <div className="d-grid gap-2 mt-3">
              <button disabled={mentoring.length > 1} onClick={() => setMentoring([...mentoring, {
                institutionId: 388,
                divisions: []
              }])} className='btn btn-primary'>Tambah</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default MentorsAdd;

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../../../../utils/api';
import ToastNotifications from '../../../../Components/ToastNotifications';

const MediaSosial = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState({});
  const location = useLocation();
  const { user } = location.state;

  const [messageNotifications, setMessage] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    setProfile(user);
  }, []);

  const onHandleSubmit = async () => {
    // if (profile?.mob_kep_pengajuan_kepulangan === 'Tidak Mengajukan Kepulangan') {
    //   setProfile({
    //     ...profile,
    //     mob_kep_provinsi_kepulangan: "",
    //     mob_kep_moda_kepulangan: "",
    //     mob_kep_provinsi_asal_keberangkatan: "",
    //     mob_kep_bandara_stasiun_kepulangan: "",
    //     mob_kep_prov_bandara_stasiun_kepulangan: "",
    //     mob_kep_bandara_stasiun_tujuan: "",
    //     mob_kep_prov_bandara_stasiun_tujuan: "",
    //   });
    //   console.log('kepulangan!')
    // }

    // if (profile?.mob_keb_pengajuan_keberangkatan === 'Tidak Mengajukan Keberangkatan') {
    //   setProfile({
    //     ...profile,
    //     mob_keb_provinsi_keberangkatan: "",
    //     mob_keb_moda_keberangkatan: "",
    //     mob_keb_provinsi_asal_keberangkatan: "",
    //     mob_keb_bandara_stasiun_keberangkatan: "",
    //     mob_keb_prov_bandara_stasiun_keberangkatan: "",
    //     mob_keb_bandara_stasiun_tujuan: "",
    //     mob_keb_prov_bandara_stasiun_tujuan: "",
    //   });
    //   console.log('keberangkatan!')
    // }

    const assingn = Object.assign(user, profile);
    const { nim } = user;

    const {
      username_instagram,
      username_tiktok,
      username_linkedin
    } = assingn;

    if (username_instagram === "") return alert('Instagram Kosong!')
    if (username_tiktok === "") return alert('Tiktok Kosong!')
    if (username_linkedin === "") return alert('LinkedIn Kosong!')

    const { status, message, data } = await api.updateProfileByNim({ profile: assingn, nim });
    const {
      nama,
      asal_universitas,
    } = data;
    if (status === 'success') {
      setMessage(`Sukses Mengubah Data ${nama} - ${asal_universitas}`);
      setShow(true);
      // alert(messageNotifications);
      setTimeout(() => {
        navigate('/app/profile');
      }, 2000);
    } else {
      setMessage(message);
      setShow(true);
      // navigate('/app/profile');
    }
  }

  return (
    <>
      <div className="py-5 text-center">
        <h2>III. MEDIA SOSIAL</h2>
        <p className="lead">Bagian pendataan media sosial ini bertujuan untuk mendukung eksposur dan portofolio mahasiswa. Semua karya yang berkaitan dengan kegiatan mahasiswa akan ditag agar mahasiswa mendapat eksposur. Prestasi-prestasi seperti sertifikat juga akan ditag ke akun mahasiswa, termasuk di LinkedIn mahasiswa bisa mendapat rekomendari dari akun BCF dan akun Tim BCF.</p>
      </div>

      <div className="row g-5">

        <div className="col-md-12 col-lg-8">

          <form className="needs-validation mb-5" noValidate="">

            <div className="shadow-sm">
              <div className="card-body p-3">

                <div class="alert alert-primary d-flex align-items-center" role="alert">
                  <div>
                    <p>Contoh Pengisian Link Sosmed</p>
                    <ul>
                      <li>https://www.instagram.com/bakriecenter menjadi bakriecenter</li>
                      <li>https://www.tiktok.com/@sehatberdayalestari menjadi sehatberdayalestari</li>
                      <li>https://www.linkedin.com/in/bakriecenter menjadi bakriecenter</li>
                    </ul>
                    <p>Jika tidak mempunyai salah satu sosmed isi dengan ( - ) strip.</p>
                  </div>
                </div>

                <div className="row g-3">
                  <div className="col-12">
                    <label htmlFor="address" className="form-label">Username Akun Instagram Mahasiswa</label>
                    <input onChange={(e) => setProfile({ ...profile, username_instagram: e.target.value })} value={profile?.username_instagram} type="text" className="form-control" id="address" required="" />
                  </div>

                  <div className="col-12">
                    <label htmlFor="address" className="form-label">Username Akun TikTok Mahasiswa</label>
                    <input onChange={(e) => setProfile({ ...profile, username_tiktok: e.target.value })} value={profile?.username_tiktok} type="text" className="form-control" id="address" required="" />
                  </div>

                  <div className="col-12">
                    <label htmlFor="address" className="form-label">Username Akun LinkedIn Mahasiswa</label>
                    <input onChange={(e) => setProfile({ ...profile, username_linkedin: e.target.value })} value={profile?.username_linkedin} type="text" className="form-control" id="address" required="" />
                  </div>
                </div>

              </div>
            </div>

            <button onClick={() => onHandleSubmit()} className="w-100 btn btn-primary btn-lg my-3" type="button">Simpan</button>

          </form>
        </div>

        <ToastNotifications setShowNotifications={setShow} show={show} message={messageNotifications} />
      </div>
    </>
  )
}

export default MediaSosial